import axios from "axios";

export default function CompanydocumentFormService(formData, method, companyId, documentId) {
  let url = process.env.REACT_APP_API_HOST + "/companyDocument/" + companyId
  if(documentId) url = process.env.REACT_APP_API_HOST + "/companyDocument/" + companyId + "/" + documentId
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
