import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import download from 'js-file-download';

import EntrylogDailyreportDownloadService from "../../services/entrylog/EntrylogDailyreportDownloadService";
import axios from "axios";
import {InputMask} from "primereact/inputmask";

const EntrylogDailyreportFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    EntrylogDailyreportDownloadService(data)
      .then(response => {
        axios({
          method: 'post',
          url: process.env.REACT_APP_API_HOST + '/report/entry_location_daily_headcount',
          data: formData,
          params: {},
          responseType: 'blob',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
            'App-Network': localStorage.getItem("networkId")
          }
        })
          .then(response => {
            window.App.toastShow('success', 'Sikeres letöltés!', '')
            download(response.data, localStorage.getItem('networkName')
              + '_'
              +
              'letszamjelentes'
              + '_'
              + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
              + '.xlsx')
            setLoading(false)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false)
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit}>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={"w-100"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="dateFrom" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="dateFrom"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Időpont kezdete</label>
                  <span className="p-input-icon-right">
                        <InputMask id={"dateFrom"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.dateFrom}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         dateFrom: e.value
                                       })
                                     }
                                   }}
                        />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="dateUntil" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="dateUntil"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Időpont vége</label>
                  <span className="p-input-icon-right">
                        <InputMask id={"dateUntil"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.dateUntil}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         dateUntil: e.value
                                       })
                                     }
                                   }}
                        />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Letöltés" icon="pi pi-download" loading={loading}
                      className="p-button"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EntrylogDailyreportFormComponent);
