import axios from "axios";

function UserLoginService(formData) {
  return axios.post(process.env.REACT_APP_API_HOST + "/login_check", {
      username: formData.username,
      password: formData.password
    }, {}
  )
}

export default UserLoginService;
