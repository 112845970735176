import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Badge} from 'primereact/badge';

export const DashboardPage = (props) => {

  useEffect(() => {
    //window.App.pageHeight()
    document.title = 'Vezérlőpult' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
  });

  return (
    <div className="DashboardPage p-component p-p-3">
      {/*<div className={"p-grid p-d-flex p-flex-wrap"}>*/}
      {/*  <div className={"p-col-12 p-lg-8 p-p-5"}>*/}
      <div className={""} style={{maxWidth: '950px', margin: '0 auto'}}>
        {localStorage.getItem('helpWelcome') === true || !localStorage.getItem('helpWelcome') &&
        <div className={"p-mt-4 p-pt-5"}>
          <Card title="Üdvözöl a Trackie.io" subTitle={"2.0 Béta verzió!"} id="welcome"
                className={"p-p-3 p-pb-0"}
                footer={
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-6"}>
                        <Button icon="pi pi-times" className="p-button p-button-secondary" label={"Bezár"}
                                onClick={() => {
                                  if (window.confirm('Bezárást követően a következő bejelentkezésig nem jelenik meg ez az üzenet!')) {
                                    localStorage.setItem('helpWelcome', false);
                                    window.App.forceUpdate()
                                  }
                                }}
                        />
                      </div>
                      <div className={"p-col-6 p-text-right"}>
                        {/*<Button icon="pi pi-download" className="p-button" label={"Bemutató letöltése"}*/}

                        {/*/>*/}
                      </div>
                    </div>
                  </>
                }
          >
            <p className={""}>
              Az elmúlt másfél évben rengeteg ügyfél igénye alapján fejlesztettük szoftverünk moduljait, melyek mára
              messze túlmutatnak a számlakiállításon. A következő két hónapban még az utolsó
              simításokat végezzük, de Trackie.io a jövő év elejétől az alábbi témákban segíti előfiezetőit:
            </p>
            <div className={"p-text-center"}>
              <Badge className={"p-m-1"} value="pénzügyi nyilvántartás"></Badge>
              <Badge className={"p-m-1"} value="bizonylatok kiállítása"></Badge>
              <Badge className={"p-m-1"} value="online számla"></Badge>
              <Badge className={"p-m-1"} value="áfa analitika"></Badge>
              <Badge className={"p-m-1"} value="munkaügyi nyilvántartás"></Badge>
              <Badge className={"p-m-1"} value="műszakok és beosztások"></Badge>
              <Badge className={"p-m-1"} value="digitális jelenléti ív"></Badge>
              <Badge className={"p-m-1"} value="munkaügyi előírások"></Badge>
              <Badge className={"p-m-1"} value="efo"></Badge>
              <Badge className={"p-m-1"} value="dokumentum generálás"></Badge>
              <Badge className={"p-m-1"} value="projektek kezelése"></Badge>
              <Badge className={"p-m-1"} value="árajánlatok"></Badge>
              <Badge className={"p-m-1"} value="beszerzések"></Badge>
              <Badge className={"p-m-1"} value="gyártás tervezés és ütemezés"></Badge>
              <Badge className={"p-m-1"} value="készletkezelés"></Badge>
              <Badge className={"p-m-1"} value="raktárkezelés"></Badge>
              <Badge className={"p-m-1"} value="megrendelések"></Badge>
              <Badge className={"p-m-1"} value="teljesítések és elszámolások"></Badge>
              <Badge className={"p-m-1"} value="kimutatások és riportok"></Badge>
              <Badge className={"p-m-1"} value="döntéstámogatás"></Badge>
              <Badge className={"p-m-1"} value="egyedi rendszer integrációk"></Badge>
            </div>
            <p className={""}>
              2023. október 29-től előfizetőink számára bemutató jelleggel teljeskörűen elérhető az újgenerációs
              számlázás a Trackie.io-ban. Igyekeztünk a lehető legalaposabban letesztelni a szoftverünket, de ettől még biztosan
              előfordulnak hibák. Továbbra is várjuk a véleményeket a szoftverrel kapcsolatban, vagy a hibajelzéseket
              képernyőfotóval együtt a(z) <a
              href={"mailto:" + process.env.REACT_APP_DEVOPS_CONTACT}>{process.env.REACT_APP_DEVOPS_CONTACT}</a> email
              címre. <strong>
              Köszönjük a türelmet!</strong>
            </p>
          </Card>
        </div>
        }

        <div className={"p-mt-4 p-pt-5"}>
          <div className={"p-text-center p-p-5 right text-light"}
               style={{borderRadius: '4px'}}>
            <div className={"p-grid p-d-flex p-flex-wrap p-px-2"}>
              {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
              <div className={"p-col p-d-flex"}>
                <Link to={"/subscriptions"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-building bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    <strong>Előfizetői</strong> fiók
                  </Card>
                </Link>
              </div>
              }
              {localStorage.getItem('module_settings') ? (
                <div className={"p-col p-d-flex"}>
                  <Link to={"/settings"} className={"w-100"}>
                    <Card className={"p-text-center w-100 shadow-none"}>
                      <i className="pi pi-cog bg-white p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Beállítások</strong>
                    </Card>
                  </Link>
                </div>
              ) : (
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100 disabled"}>
                    <Card className={"p-text-center w-100 shadow-none"}>
                      <i className="pi pi-cog bg-white p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Beállítások</strong>
                    </Card>
                  </a>
                </div>
              )}
            </div>
            <div className={"p-grid p-d-flex p-flex-wrap p-px-2"}>
              {localStorage.getItem('module_partner')
                ? (
                  <div className={"p-col p-d-flex"}>
                    <Link to={"/partner"} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-briefcase bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Partner</strong> kezelés
                      </Card>
                    </Link>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-briefcase bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Partner</strong> kezelés
                      </Card>
                    </a>
                  </div>
                )}
              {localStorage.getItem('module_finance')
                ? (
                  <div className={"p-col p-d-flex"}>
                    <Link to={"/finance"} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-wallet bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Pénzügy</strong> és nyilvántartás
                      </Card>
                    </Link>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-wallet bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Pénzügy</strong> és nyilvántartás
                      </Card>
                    </a>
                  </div>
                )}
              {localStorage.getItem('module_order')
                ? (
                  <div className={"p-col p-d-flex"}>
                    <Link to={"/order"} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-shopping-cart bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Megrendelések</strong> kezelése és számlázása
                      </Card>
                    </Link>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-shopping-cart bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Megrendelések</strong> kezelése és számlázása
                      </Card>
                    </a>
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className={"p-mt-4 p-pt-5"}>
          <img src={require("../assets/img/undraw_Data_trends_re_2cdy.png").default}
               width={"100%"} style={{borderRadius: '4px'}}/>
        </div>

        <div className={"p-mt-4 p-py-5"}>
          <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-secondary"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
          </div>
        </div>

        {/*<h2 className={"p-pt-0 p-mb-5 text-primary p-text-center p-text-lg-left"}>*/}
        {/*  Folyamatban lévő fejlesztéseink*/}
        {/*</h2>*/}
        {/*<div className={"p-grid p-mt-5"}>*/}
        {/*  <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>*/}
        {/*    <i className="pi pi-shopping-cart bg-white"*/}
        {/*       style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>*/}
        {/*  </div>*/}
        {/*  <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>*/}
        {/*    <strong>Webshop integrációk</strong>*/}
        {/*    <p className={"p-mb-0"}>*/}
        {/*      Számlázható rendelések összegyűjtése webáruházakból és azok automatikus kiszámlázása, majd*/}
        {/*      kézbesítése a vevő részére. Elérhető integrációk: UNAS webáruház vagy egyedi API haszhálat.*/}
        {/*      Előrendelhető integrációk: Wordpress WooCommerce, Prestashop, Shoprenter*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<hr className={"p-my-5"}/>*/}
        {/*<div className={"p-grid p-mt-5"}>*/}
        {/*  <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>*/}
        {/*    <i className="pi pi-chart-bar bg-white"*/}
        {/*       style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>*/}
        {/*  </div>*/}
        {/*  <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>*/}
        {/*    <strong>Pénzügyi kontrolling</strong>*/}
        {/*    <p className={"p-mb-0"}>*/}
        {/*      Lejelentett költségszámlák importálása a NAV rendszeréből vagy kézi rögzítése számlakép*/}
        {/*      feltöltéssel. Electra formátum importálása, majd az importált banki tranzakciók párosítása*/}
        {/*      számlákkal és/vagy költségszámlákkal. A számlákat lefedő banki tranzakciók alapján a rendszer*/}
        {/*      automatikusan fizetettre állítja az adott számlákat. Bevételek, kintlévőségek és ÁFA fizetési*/}
        {/*      információk.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<hr className={"p-my-5"}/>*/}
        {/*<div className={"p-grid p-mt-5"}>*/}
        {/*  <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>*/}
        {/*    <i className="pi pi-users bg-white"*/}
        {/*       style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>*/}
        {/*  </div>*/}
        {/*  <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>*/}
        {/*    <strong>CRM funkciók</strong>*/}
        {/*    <p className={"p-mb-0"}>*/}
        {/*      Fejlett partner kezelés partner forgalmi adatainak, cégméret és látszám adatainak rögzítésével,*/}
        {/*      értékesítési folyamatok naplózása és tennivalók meghatrozása dolgozók között. Figyelmeztetések és*/}
        {/*      emlékeztetők kezelése.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<hr className={"p-my-5"}/>*/}
        {/*<div className={"p-mt-5 p-pt-5 p-text-center"}>*/}
        {/*  <a href={"mailto:" + process.env.REACT_APP_DEVOPS_CONTACT}*/}
        {/*     className={"p-button p-button-lg p-button-success"}>*/}
        {/*    Érdeklődj előfizetési lehetőségekről!*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}
export default withTranslation('common')(DashboardPage);
