import axios from "axios";

export default function EntryzoneWorktypeAttachableListService(query, entryzoneId) {
  if(query.includes('"multiSortMeta":[],', '')) {
    query = query.replace('"multiSortMeta":[],', '')
  }
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/entry_zone/" + entryzoneId + "/attachableWorkTypes?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
