import axios from "axios";

export default function EntrylogExportService(query) {
    if(query && query.toString().includes(',"multiSortMeta":[]')) {
    query = query.replace(',"multiSortMeta":[]', '')
  }

  return axios.get(process.env.REACT_APP_API_HOST + "/entry_log/download?" + query, {
    responseType: 'blob',
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
};
