import axios from "axios";

export default function EmployeeInviteService(employeeId) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/employee/" + employeeId + "/invite",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
