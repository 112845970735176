import axios from "axios";

export default function ScheduleitemsProductionItemAddService(scheduleItemId, productionItemId, position, estimatedTime) {
  return axios({
    method: 'post',
    data: {
      position: position,
      estimatedTime: estimatedTime,
      productionItem: {id: productionItemId}
    },
    url: process.env.REACT_APP_API_HOST + "/schedule_item/" + scheduleItemId + "/production_job",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
