import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import {Panel} from "primereact/panel";
import hu from "../../translations/hu/kalend.json"
import ScheduleAllService from "../../services/schedule/ScheduleAllService";
import moment from "moment";

const SchedulegroupShowComponent = (props) => {

  const [events, setEvents] = useState([]);
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateUntil, setFilterDateUntil] = useState('');

  useEffect(() => {
  }, [])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>

        </div>
        <div style={{height: '665px'}}>
          <Kalend
            customLanguage={hu}
            onPageChange={(e) => {
              ScheduleAllService(
                window.App.state.props.id,
                JSON.stringify({
                  filters: {
                    dateFrom:{value: moment.utc(e.rangeFrom).local().format('YYYY-MM-DD')},
                    dateUntil:{value: moment.utc(e.rangeTo).local().format('YYYY-MM-DD')}
                  }
                })
              )
                .then(response => {
                  let _events = []
                  response.data.map(function (item, index) {
                    if (item) {
                      _events.push({
                        id: item.id,
                        startAt: item.dateFrom,
                        endAt: item.dateUntil,
                        summary: 'Létszám: ' + item.headcount,
                        // color: 'blue',
                        // calendarID: 'work'
                      })
                    }
                  })
                  setEvents(_events)
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                })
            }}
            events={events}
            initialDate={new Date().toISOString()}
            hourHeight={20}
            initialView={CalendarView.WEEK}
            disabledViews={[CalendarView.THREE_DAYS]}
            timeFormat={'24'}
            weekDayStart={'Monday'}
            language={'en'}
            onEventClick={(e) => {
              window.App.setState({
                sidebarScheduleShow: true,
                props: {id: e.id},
                valueSchedulegroupId: props.initialValues.id,
              })
            }}
          />
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(SchedulegroupShowComponent);
