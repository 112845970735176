import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';
import {Panel} from "primereact/panel";

import ProductListComponent from "../../components/product/ProductListComponent";
import ProductFormComponent from "../../components/product/ProductFormComponent";
import ProductImportComponent from "../../components/product/ProductImportComponent";
import InventoryListComponent from "../../components/inventory/InventoryListComponent";
import InventorytransferFormComponent from "../../components/inventorytransfer/InventorytransferFormComponent";
import InventorytransferListComponent from "../../components/inventorytransfer/InventorytransferListComponent";

export const InventoryDashboardPage = () => {

  useEffect(() => {
      window.App.pageHeight()
      document.title = 'Készlet' + ' - ' + process.env.REACT_APP_TITLE;
      setTimeout(function () {
        if (document.getElementsByClassName('activeMenu')[0]) {
          document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
        }
        if (document.getElementsByClassName('inventory')[0]) {
          document.getElementsByClassName('inventory')[0].classList.add("activeMenu")
        }
      }, 1000)
      if (document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]) {
        document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
      }
    }, []
  );

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {1 == 1 ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_product_read') ? (
                  <TabPanel header="Terméktörzs">
                    <div className={"p-p-5"}>
                      <ProductListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_inventory_read') ? (
                  <TabPanel header="Készlet">
                    <div className={"p-p-5"}>
                      <InventoryListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_inventory_transfer_read') ? (
                  <TabPanel header="Árumozgások">
                    <div className={"p-p-5"}>
                      <InventorytransferListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_product_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarProductCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új termék
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_product_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarProductImport: true})}
                       className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-cloud-upload bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Terméktörzs importálása
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_inventory_transfer_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({
                      sidebarInventorytransferCreate: true,
                      props: {
                        action: 'move'
                      }
                    })} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-reply bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Árumozgatás
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_inventory_transfer_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({
                      sidebarInventorytransferCreate: true,
                      props: {
                        action: 'control'
                      }
                    })} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-database bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Leltár
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_inventory_transfer_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({
                      sidebarInventorytransferCreate: true,
                      props: {
                        action: 'waste'
                      }
                    })} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-trash bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Selejt
                      </Card>
                    </a>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarProductCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProductCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új termék</h2>
            <ProductFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProductUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProductUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Termék módosítása</h2>
            <ProductFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProductImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProductImport: false})
                   }}>
            <h2 className={"text-primary"}>Terméktörzs importálása</h2>
            <ProductImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarInventorytransferCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarInventorytransferCreate: false})
                   }}>
            <InventorytransferFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(InventoryDashboardPage);
