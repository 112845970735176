import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {FileUpload} from "primereact/fileupload";

import ReceiptCostImportService from "../../services/receipt/ReceiptCostImportService";
import PartnerListService from "../../services/partner/PartnerListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import CompanyListService from "../../services/company/CompanyListService";
import {Message} from "primereact/message";
import ImportqueueListComponent from "../importqueue/ImportqueueListComponent";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import ReceiptCostImportNavService from "../../services/receipt/ReceiptCostImportNavService";
import {InputMask} from "primereact/inputmask";

const ReceiptCostImportComponent_ = (props) => {

  //default form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [receiptgroups, setReceiptgroups] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ReceiptCostImportService(formData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setFormData({...formData, xls: null})
        window.App.setState({
          //sidebarReceiptCostImport: false,
          rerenderReceiptList: true,
          rerenderImportqueueList: true,
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };

  const [navFormData, setNavFormData] = useState({});
  const [navLoading, setNavLoading] = useState(false);

  const navValidate = (data) => {
    let errors = {};
    return errors;
  };

  const onNavSubmit = (data, form) => {
    setNavLoading(true)
    setNavFormData({
      ...navFormData,
      data
    });
    ReceiptCostImportNavService(navFormData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        window.App.setState({
          //sidebarReceiptCostImport: false,
          rerenderReceiptList: true,
          rerenderImportqueueList: true,
        })
        setNavLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setNavLoading(false)
      })
    form.restart();
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, '')
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const [selectedNavCompany, setSelectedNavCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchNavCompany = (event) => {
    setSelectedNavCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedNavCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    let _formData = {}
    ReceiptgroupListService()
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (localStorage.getItem('defaultCompanyId')) {
      _formData = {
        ..._formData,
        company: {
          id: Number(localStorage.getItem('defaultCompanyId'))
        }
      }
    }
    if (window.App.state.props && window.App.state.props.receiptgroupId) {
      _formData = {
        ..._formData,
        receiptGroupId: window.App.state.props.receiptgroupId
      }
      setNavFormData({
        ...navFormData,
        receiptGroupId: window.App.state.props.receiptgroupId
      })
    }
    setFormData(_formData)
    setNavFormData(_formData)
  }, [])

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={formData}
            validate={validate} render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} className="">
          <Panel className={""}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
                <h3>
                  <i className={"pi pi-download"}></i> Fájl letöltése
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                Kérjük töltse ki a mellékelt minta XLS fájlt.
              </div>
              <div className={"p-col-12 p-md-6 p-lg-6 p-text-right"}>
                <a href="/download/koltsegszamlak_import_minta.xlsx"
                   download
                   target="_blank"
                   className={"p-button p-text-light"}>
                  <i className={"pi pi-download"}></i>&nbsp;Minta XLS letöltése
                </a>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
                <h3>
                  <i className={"pi pi-upload"}></i> Fájl feltöltése
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              {/*<div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
              {/*  <Field name="receiptGroup" render={({input, meta}) => (*/}
              {/*    <div className="p-field p-fluid">*/}
              {/*      <label htmlFor="receiptGroup"*/}
              {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>*/}
              {/*      <span className="p-input-icon-right">*/}
              {/*            <Dropdown {...input}*/}
              {/*                      disabled*/}
              {/*                      value={Number(window.App.state.props.receiptgroupId)}*/}
              {/*                      options={receiptgroups}*/}
              {/*                      optionLabel="name"*/}
              {/*                      optionValue="id"*/}
              {/*                      id={"receiptGroup"}*/}
              {/*                      emptyMessage={"Még nincs számlatömb..."}*/}
              {/*                      className={classNames({'p-error': isFormFieldValid(meta)})}*/}
              {/*            />*/}
              {/*          </span>*/}
              {/*      {getFormErrorMessage(meta)}*/}
              {/*    </div>*/}
              {/*  )}/>*/}
              {/*</div>*/}
              <div className={"p-col"}>
                <Field name="company" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="company"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő</label>
                    <span className="p-input-icon-right">
                            <AutoComplete value={selectedCompany}
                                          id={"company"}
                                          suggestions={companies}
                                          forceSelection
                                          completeMethod={searchCompany}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: e.value.id}
                                            })
                                            localStorage.setItem('defaultCompanyId', e.value.id)
                                            localStorage.setItem('defaultCompanyName', e.value.name)
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: null}
                                            })
                                            setSelectedCompany(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedCompany('')
                                          }}
                            />
                          </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col"}>
                <Field name="file" render={({input, meta}) => (
                  <div className="p-field">
                    {!formData.file ? (
                      <>
                        <label htmlFor="image"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                        <span className="">
                               <FileUpload mode="basic"
                                           name="file"
                                           id={"file"}
                                           accept=""
                                           auto="true"
                                 //maxFileSize={1000000}
                                           customUpload
                                           uploadHandler={fileUpload}
                                           chooseLabel={"Fájl kiválasztása"}
                                           uploadLabel={"Feltöltés"}
                               />
                            </span>
                      </>
                    ) : (
                      <>
                        <label htmlFor="image"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                          törlése</label>
                        <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger"
                                      icon="pi pi-trash"
                                      label={"Törlés"}
                                      onClick={() => setFormData({...formData, file: null})}
                              />
                            </span>
                      </>
                    )}
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Message severity="warn" text="
                A költségszámla import csak a letölthető mintának megfelelő formátumú xlsx fájlokkal használható.
              "></Message>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                {formData.file ? (
                  <Button type="submit" label="Import indítása" icon={"pi pi-check"} loading={loading}
                          className="p-button-success"/>
                ) : (
                  <Button type="button" label="Import indítása"
                          icon={"pi pi-check"}
                          className="p-button-success"
                          onClick={() => {
                            alert('Tölts fel egy XLS fájlt!')
                          }}
                  />
                )}
              </div>
            </div>
          </Panel>
        </form>
      )}/>

      {process.env.REACT_APP_COMPANY_NAV_INTERFACE === 'true' &&
      <Form onSubmit={onNavSubmit} initialValues={navFormData}
            validate={navValidate} render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} className="">
          <Panel className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
                <h3>
                  <i className={"pi pi-download"}></i> Importálás a NAV rendszeréből
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col"}>
                <Field name="companyId" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="companyId"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő</label>
                    <span className="p-input-icon-right">
                            <AutoComplete value={selectedNavCompany}
                                          id={"company"}
                                          suggestions={companies}
                                          forceSelection
                                          completeMethod={searchCompany}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setNavFormData({
                                              ...navFormData,
                                              company: {id: e.value.id}
                                            })
                                            localStorage.setItem('defaultCompanyId', e.value.id)
                                            localStorage.setItem('defaultCompanyName', e.value.name)
                                          }}
                                          onChange={(e) => {
                                            setNavFormData({
                                              ...navFormData,
                                              company: {id: null}
                                            })
                                            setSelectedNavCompany(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedNavCompany('')
                                          }}
                            />
                          </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col"}>
                <Field name="dateFrom" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="dateFrom"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Időszak kezdete</label>
                    <span className="p-input-icon-right">
                      <InputMask id={"dateFrom"}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 value={navFormData.dateFrom}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setNavFormData({
                                       ...navFormData,
                                       dateFrom: e.value
                                     })
                                   }
                                 }}
                      />
                        </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col"}>
                <Field name="dateUntil" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="dateUntil"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Időszak vége</label>
                    <span className="p-input-icon-right">
                      <InputMask id={"dateUntil"}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 value={navFormData.dateUntil}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setNavFormData({
                                       ...navFormData,
                                       dateUntil: e.value
                                     })
                                   }
                                 }}
                      />
                        </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Message severity="warn" text="
                A maximum megadható időintervallum 35 nap lehet.
              "></Message>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Import indítása" icon={"pi pi-check"} loading={navLoading}
                        className="p-button-success"/>
              </div>
            </div>
          </Panel>
        </form>
      )}/>
      }
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
            <h3>
              <i className={"pi pi-upload"}></i> Import folyamatok
            </h3>
          </div>
        </div>
        <ImportqueueListComponent/>
      </Panel>
    </>
  )
}
export default withTranslation('common')(ReceiptCostImportComponent_);
