import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";
import {Button} from "primereact/button";
import download from "js-file-download";
import moment from "moment";
import {Sidebar} from "primereact/sidebar";
import {OverlayPanel} from 'primereact/overlaypanel';

import EntrylogCountByZoneService from "../../services/entrylog/EntrylogCountByZoneService";
import EntrylogDownloadReportService from "../../services/entrylog/EntrylogDownloadReportService";
import SafetyDownloadReportService from "../../services/safety/SafetyDownloadReportService";
import EntrylogWorktimereportListComponent from "../../components/entrylog/EntrylogWorktimereportListComponent";
import EntrylogListComponent from "../../components/entrylog/EntrylogListComponent";
import EntrylocationDownloadByNetworkIdReportService
  from "../../services/entrylocation/EntrylocationDownloadByNetworkIdReportService";
import EntrylogDailyreportFormComponent from "../../components/entrylog/EntrylogDailyreportFormComponent";
import EmployeeExportService from "../../services/employee/EmployeeExportService";
import SupervisoryUnitService from "../../services/report/SupervisoryUnitService";
import EntrylocationByNetworkShowService from "../../services/entrylocation/EntrylocationByNetworkShowService";

export const _ReportDashboardPage_Entryfish = (props) => {

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [totCount, setTotCount] = useState(0);
  const [entrylocation, setEntrylocation] = useState({})

  const op = useRef(null);

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Kimutatások' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
    if (document.getElementsByClassName('report')[0]) {
      document.getElementsByClassName('report')[0].classList.add("activeMenu")
    }
    if (localStorage.getItem('networkId') && localStorage.getItem('roles').toString().includes('role_entry_log_read')) {
      EntrylogCountByZoneService()
        .then(response => {
          let chartData = []
          let chartLabels = []
          let tot = 0
          Object.entries(response.data).map(function (data, index) {
            if (data && data[1] && data[1].count && data[1].name) {
              tot = tot + Number(data[1].count)
              chartData.push(data[1].count)
              chartLabels.push(data[1].name)
            }
          })
          setChartData(chartData)
          setChartLabels(chartLabels)
          setTotCount(tot)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    EntrylocationByNetworkShowService(localStorage.getItem('networkId'))
      .then(response => {
        setEntrylocation(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  return (
    <>
      <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ReportDashboardPage">
        <div className={"p-grid p-d-flex p-flex-wrap"}>
          <div className={"p-col-12 p-lg-8 p-xl-8 p-p-5 left p-position-relative"}>

            <div className={"p-text-center p-my-5 p-p-5 right text-light"}
                 style={{maxWidth: '950px', margin: '0 auto', borderRadius: '3px', backgroundColor: 'white'}}>
              <div className={"p-grid p-d-flex p-flex-wrap"}>
                {localStorage.getItem('roles').toString().includes('role_entry_log_live') ? (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 text-light"}
                       onClick={() => {
                         window.App.setState({loadingEntrylogDownloadReport: true})
                         EntrylogDownloadReportService()
                           .then(response => {
                             download(response.data, localStorage.getItem('networkName')
                               + '_'
                               + 'belepettek-kimutatasa'
                               + '_'
                               + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                               + '.xlsx')
                             window.App.setState({loadingEntrylogDownloadReport: false})
                           })
                           .catch(error => {
                             window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                             window.App.setState({loadingEntrylogDownloadReport: false})
                           })
                       }}
                    >
                      <Card className={"p-text-center w-100 shadow-none highlighted"}
                            style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                        <i className="pi pi-id-card p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Belépettek</strong> letöltése
                      </Card>
                    </a>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 text-light disabled"}>
                      <Card className={"p-text-center w-100 shadow-none highlighted"}
                            style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                        <i className="pi pi-id-card p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Belépettek</strong> letöltése
                      </Card>
                    </a>
                  </div>
                )}
                {localStorage.getItem('roles').toString().includes('role_occ_safety_download') ? (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 text-light"}
                       onClick={() => {
                         window.App.setState({loadingSafetyDownloadReport: true})
                         SafetyDownloadReportService()
                           .then(response => {
                             download(response.data, localStorage.getItem('networkName')
                               + '_'
                               + 'munkaugyi-bejegyzesek-kimutatas'
                               + '_'
                               + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                               + '.xlsx')
                             window.App.setState({loadingSafetyDownloadReport: false})
                           })
                           .catch(error => {
                             window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                             window.App.setState({loadingSafetyDownloadReport: false})
                           })
                       }}
                    >
                      <Card className={"p-text-center w-100 shadow-none highlighted"}
                            style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                        <i className="pi pi-shield p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Munkavédelmi bejegyzések</strong> letöltése
                      </Card>
                    </a>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 text-light disabled"}>
                      <Card className={"p-text-center w-100 shadow-none highlighted"}
                            style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                        <i className="pi pi-shield p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Munkavédelmi bejegyzések</strong> letöltése
                      </Card>
                    </a>
                  </div>
                )}
                {localStorage.getItem('roles').toString().includes('role_entry_location_report') ? (
                  <>
                    <div className={"p-col p-d-flex"}>
                      <a className={"w-100"}
                         onClick={() => {
                           window.App.setState({loadingReportDownloadReport: true})
                           EntrylocationDownloadByNetworkIdReportService(localStorage.getItem('networkId'))
                             .then(response => {
                               download(response.data, localStorage.getItem('networkName')
                                 + '_'
                                 + 'projekt-kimutatas'
                                 + '_'
                                 + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                 + '.xlsx')
                               window.App.setState({loadingReportDownloadReport: false})
                             })
                             .catch(error => {
                               window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                               window.App.setState({loadingReportDownloadReport: false})
                             })
                         }}
                      >
                        <Card className={"p-text-center w-100 shadow-none highlighted"}
                              style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                          <i className="pi pi-clone p-d-block p-mb-2"
                             style={{'fontSize': '2em'}}></i>
                          <strong>Projekt kimutatás</strong> letöltése
                        </Card>
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={"p-col p-d-flex"}>
                      <a className={"w-100 disabled"}>
                        <Card className={"p-text-center w-100 shadow-none highlighted"}
                              style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                          <i className="pi pi-clone p-d-block p-mb-2"
                             style={{'fontSize': '2em'}}></i>
                          <strong>Projekt kimutatás</strong> letöltése
                        </Card>
                      </a>
                    </div>
                  </>
                )}
                {process.env.REACT_APP_NETWORK_SEPARATION === 'ENTRY_LOCATION' &&
                <>
                  {localStorage.getItem('roles').toString().includes('role_supervisor_unit_sync_report') && entrylocation && entrylocation.supervised && entrylocation.supervised === true ? (
                    <>
                      <div className={"p-col p-d-flex"}>
                        <a className={"w-100"}
                           onClick={() => {
                             window.App.setState({loadingSupervisoryReport: true})
                             SupervisoryUnitService()
                               .then(response => {
                                 download(response.data, localStorage.getItem('networkName')
                                   + '_'
                                   + 'uvegkapu-report'
                                   + '_'
                                   + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                   + '.xlsx')
                                 window.App.setState({loadingSupervisoryReport: false})
                               })
                               .catch(error => {
                                 window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                 window.App.setState({loadingSupervisoryReport: false})
                               })
                           }}
                        >
                          <Card className={"p-text-center w-100 shadow-none highlighted"}
                                style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                            <i className="pi pi-ticket p-d-block p-mb-2"
                               style={{'fontSize': '2em'}}></i>
                            <strong>Üvegkapu jelentés</strong> letöltése
                          </Card>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={"p-col p-d-flex"}>
                        <a className={"w-100 disabled"}>
                          <Card className={"p-text-center w-100 shadow-none highlighted"}
                                style={{backgroundColor: '#11d8d8', color: '#19386e !important'}}>
                            <i className="pi pi-ticket p-d-block p-mb-2"
                               style={{'fontSize': '2em'}}></i>
                            <strong>Üvegkapu jelentés</strong> letöltése
                          </Card>
                        </a>
                      </div>
                    </>
                  )}
                </>
                }
              </div>
              <div className={"p-grid p-mt-1"}>
                {localStorage.getItem('roles').toString().includes('role_employee_export') ? (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100"} onClick={(e) => op.current.toggle(e)}>
                      <Card className={"p-text-center w-100 shadow-none text-light"}
                            style={{backgroundColor: '#19386e'}}>
                        <i className="pi pi-users p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Munkavállaló</strong> export
                      </Card>
                    </a>
                    <OverlayPanel ref={op}>
                      <p>
                        Export módjának kiválasztása
                      </p>
                      {process.env.REACT_APP_NETWORK_SEPARATION === 'ENTRY_LOCATION' && localStorage.getItem('roles').toString().includes('role_supervisor_unit_sync_report') &&
                        <>
                          {entrylocation && entrylocation.supervised && entrylocation.supervised === true ? (
                            <Button type="button" label="Üvegkapu export" className={"p-mr-1"}
                                    onClick={() => {
                                      EmployeeExportService('supervisorUnit')
                                        .then(response => {
                                          download(response.data, localStorage.getItem('networkName')
                                            + '_'
                                            + 'munkavallalo-uvegkapu-export'
                                            + '_'
                                            + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                            + '.xlsx')
                                        })
                                        .catch(error => {
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    }}
                            />
                          ):(
                            <Button type="button" label="Üvegkapu export" className={"p-mr-1"} disabled/>
                          )}
                        </>
                      }
                      <Button type="button" label="Általános export" className={"p-ml-1"}
                              onClick={() => {
                                window.App.setState({loadingEmployeeExportReport: true})
                                EmployeeExportService('general')
                                  .then(response => {
                                    download(response.data, localStorage.getItem('networkName')
                                      + '_'
                                      + 'munkavallalo-altalanos-export'
                                      + '_'
                                      + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                      + '.xlsx')
                                    window.App.setState({loadingEmployeeExportReport: false})
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    window.App.setState({loadingEmployeeExportReport: false})
                                  })
                              }}
                      />
                    </OverlayPanel>
                  </div>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none text-light"}
                            style={{backgroundColor: '#19386e'}}>
                        <i className="pi pi-users p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Munkavállaló</strong> export
                      </Card>
                    </a>
                  </div>
                )}
                {localStorage.getItem('roles').toString().includes('role_entry_log_worktime') ? (
                  <>
                    <div className={"p-col p-d-flex"}>
                      <a className={"w-100"}
                         onClick={() => window.App.setState({sidebarWorktimereportShow: true})}
                      >
                        <Card className={"p-text-center w-100 shadow-none text-light"}
                              style={{backgroundColor: '#19386e'}}>
                          <i className="pi pi-user p-d-block p-mb-2"
                             style={{'fontSize': '2em'}}></i>
                          <strong>Munkaidő kimutatás</strong>
                        </Card>
                      </a>
                    </div>
                    <Sidebar position="left" visible={window.App.state.sidebarWorktimereportShow} closeOnEscape={true}
                             style={{width: '98%'}}
                             onHide={() => {
                               window.App.setState({sidebarWorktimereportShow: false})
                             }}>
                      <h2 className={"text-primary"}>Munkaidő kimutatás</h2>
                      <EntrylogWorktimereportListComponent view="advanced"/>
                    </Sidebar>
                  </>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none text-light"}
                            style={{backgroundColor: '#19386e'}}>
                        <i className="pi pi-user p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Munkaidő kimutatás</strong>
                      </Card>
                    </a>
                  </div>
                )}
                {localStorage.getItem('roles').toString().includes('role_entry_log_extended') ? (
                  <>
                    <div className={"p-col p-d-flex"}>
                      <a className={"w-100"}
                         onClick={() => {
                           window.App.setState({sidebarEntrylogList: true})
                         }}
                      >
                        <Card className={"p-text-center w-100 shadow-none text-light"}
                              style={{backgroundColor: '#19386e'}}>
                          <i className="pi pi-chart-bar p-d-block p-mb-2"
                             style={{'fontSize': '2em'}}></i>
                          <strong>Belépési napló</strong>
                        </Card>
                      </a>
                    </div>
                    <Sidebar position="left" visible={window.App.state.sidebarEntrylogList} closeOnEscape={true}
                             style={{width: '98%'}}
                             onHide={() => {
                               window.App.setState({sidebarEntrylogList: false})
                             }}>
                      <h2 className={"text-primary"}>Belépési napló</h2>
                      <EntrylogListComponent view="advanced"/>
                    </Sidebar>
                  </>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none text-light"}
                            style={{backgroundColor: '#19386e'}}>
                        <i className="pi pi-chart-bar p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Belépési napló</strong>
                      </Card>
                    </a>
                  </div>
                )}
                {localStorage.getItem('roles').toString().includes('role_report_daily_headcount') ? (
                  <>
                    <div className={"p-col p-d-flex"}>
                      <a className={"w-100"}
                         onClick={() => {
                           window.App.setState({sidebarEntrylogDailyreportList: true})
                         }}
                      >
                        <Card className={"p-text-center w-100 shadow-none text-light"}
                              style={{backgroundColor: '#19386e'}}>
                          <i className="pi pi-sort-numeric-down p-d-block p-mb-2"
                             style={{'fontSize': '2em'}}></i>
                          <strong>Létszámjelentés</strong>
                        </Card>
                      </a>
                    </div>
                    <Sidebar position="left" visible={window.App.state.sidebarEntrylogDailyreportList}
                             closeOnEscape={true}
                             style={{width: '780px'}}
                             onHide={() => {
                               window.App.setState({sidebarEntrylogDailyreportList: false})
                             }}>
                      <h2 className={"text-primary"}>Létszámjelentés</h2>
                      <EntrylogDailyreportFormComponent/>
                    </Sidebar>
                  </>
                ) : (
                  <div className={"p-col p-d-flex"}>
                    <a className={"w-100 disabled"}>
                      <Card className={"p-text-center w-100 shadow-none text-light"}
                            style={{backgroundColor: '#19386e'}}>
                        <i className="pi pi-sort-numeric-down p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        <strong>Létszámjelentés</strong>
                      </Card>
                    </a>
                  </div>
                )}
              </div>
            </div>

          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
            <div className={"p-text-center p-pt-5 w-75"}>
              {localStorage.getItem('roles').toString().includes('role_entry_log_live') ? (
                <>
                  <div className={"p-text-center"}>
                    {chartData && chartData[0] &&
                    <>
                      <Chart type="doughnut"
                             options={{
                               animation: {
                                 duration: 0
                               },
                               plugins: {
                                 legend: {
                                   labels: {
                                     color: '#FFF'
                                   }
                                 }
                               }
                             }}
                             data={{
                               labels: chartLabels,
                               datasets: [
                                 {
                                   data: chartData,
                                   backgroundColor: [
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                     "#11D8D8",
                                     "#D9C309",
                                     "#64748A",
                                     "#F3F4F5",
                                   ]
                                 }
                               ]
                             }} className={"p-mt-5"}/>
                    </>
                    }
                    <h3 className={"p-text-center p-text-normal p-mt-4 p-mb-0"}>Területen tartozkódok
                      száma: {totCount} fő</h3>
                    <Button className={"p-button-secondary p-button-sm p-mt-2 p-text-light"}
                            loading={window.App.state.loadingEntrylogCountByZone}
                            icon={"pi pi-refresh"}
                            label={"Frissítés"}
                            onClick={() => {
                              window.App.setState({loadingEntrylogCountByZone: true})
                              EntrylogCountByZoneService()
                                .then(response => {
                                  let chartData = []
                                  let chartLabels = []
                                  let tot = 0
                                  Object.entries(response.data).map(function (data, index) {
                                    if (data && data[1] && data[1].count && data[1].name) {
                                      tot = tot + Number(data[1].count)
                                      chartData.push(data[1].count)
                                      chartLabels.push(data[1].name)
                                    }
                                  })
                                  setChartData(chartData)
                                  setChartLabels(chartLabels)
                                  setTotCount(tot)
                                  window.App.setState({loadingEntrylogCountByZone: false})
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  window.App.setState({loadingEntrylogCountByZone: false})
                                })
                            }
                            }/>
                  </div>
                </>
              ) : (
                <div className={"p-p-5"}>
                  <img src={require("../../assets/img/symbol_entryfish_inverse.png").default} className={"p-my-5"}/>
                </div>
              )}

            </div>
            <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(_ReportDashboardPage_Entryfish);
