import axios from "axios";

export default function SubscriptionPaymentStatusService(params) {

  return axios({
    method: 'get',
    url: process.env.REACT_APP_SUBSCRIPTION_API_HOST + "/payment_status" + params,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
