import axios from "axios";

export default function ProjectItemDownloadService(projectId, itemId) {

  return axios.get(process.env.REACT_APP_API_HOST + "/project/" + projectId + "/items/" + itemId + "/download", {
    responseType: 'blob',
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
};
