import axios from "axios";

export default function InventorytransferFormService(formData, action) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/inventoryTransfer/" + action,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
