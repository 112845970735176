import axios from "axios";

export default function PurchaseorderrequestItemSaveService(requestId, itemId, quantity, warehouseId) {
  return axios({
    method: 'put',
    data: {
      quantity: Number(quantity), warehouse: {id: warehouseId}
    },
    url: process.env.REACT_APP_API_HOST + "/purchase_order_request/" + requestId + "/" + itemId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
