import axios from "axios";

export default function InventorytransferDownloadXlsService(transferId) {

  return axios.get(process.env.REACT_APP_API_HOST + "/inventoryTransfer/" + transferId + "/download/xlsx", {
    responseType: 'blob',
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
};
