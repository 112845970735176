import axios from "axios";

export default function ProjectitemSchemaService() {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/project_item_schema",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
