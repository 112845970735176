import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import moment from "moment";
import {Badge} from "primereact/badge";

import ImportqueueListService from "../../services/importqueue/ImportqueueListService";
import ImportqueueStatusesService from "../../services/importqueue/ImportqueueStatusesService";

const ImportqueueListComponent = (props) => {

  const [importqueues, setImportqueues] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [expandedRows, setExpandedRows] = useState(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderImportqueueList: false})
      ImportqueueListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setImportqueues(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <span className={""}
                dangerouslySetInnerHTML={{__html: data.note.replaceAll(';', '</br>')}}></span>
        </div>
      </>
    );
  }

  useEffect(() => {
    ImportqueueStatusesService()
      .then(response => {
        setStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    clearInterval(window.queueInterval)
    window.queueInterval = setInterval(function() {
      ImportqueueListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setImportqueues(response.data.items);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    },60000)
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderImportqueueList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={importqueues} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows}
      autoLayout={true}
      ref={dt}
      filterDisplay="row"
      responsiveLayout="scroll"
      stripedRows
      dataKey="id"
      rowExpansionTemplate={rowExpansionTemplate}
      expandedRows={expandedRows}
      onRowExpand={(e) => {
        let id = e.data.id
        setExpandedRows({[id]: true})
      }}
    >
      <Column field="status" header="Státusz" sortable body={status}></Column>
      <Column field="createdAt" header="Időpont" sortable body={(rowData) => {
        return (
          <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }}></Column>
      <Column field="userName" header="Felhasználó" sortable></Column>
      <Column field="details" header="Részletek"></Column>
      <Column expander style={{width: '3em'}}
              body={(rowData) => {
                if (rowData.note && rowData.note !== null) {
                  let id = rowData.id
                  return (
                    <span style={{cursor: 'pointer'}}
                          onClick={(e) => {
                            setExpandedRows({[id]: true})
                          }}>
                      <Badge value="!" severity="danger"></Badge>
                    </span>
                  )
                } else {
                  return (
                    <></>
                  )
                }
              }}
      />
    </DataTable>

  )
}
export default withTranslation('common')(ImportqueueListComponent);
