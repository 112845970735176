import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import {Switch} from "react-router-dom";

import ProductFormService from '../../services/product/ProductFormService'
import PartnerListService from "../../services/partner/PartnerListService";
import ManufacturerListService from "../../services/manufacturer/ManufacturerListService";
import {InputSwitch} from "primereact/inputswitch";
import {AutoComplete} from "primereact/autocomplete";
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import UnitListService from "../../services/unit/UnitListService";
import {InputNumber} from "primereact/inputnumber";

const ProductFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  // const [partners, setPartners] = useState({});
  // const [manufacturers, setManufacturers] = useState({});

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.sku) {
    //   errors.sku = 'Az sku nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      ProductFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProductList: true,
            sidebarProductUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProductFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProductList: true,
            sidebarProductCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    PartnerListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        setPartners(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // manufactiurer autocomplete
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState('');

  const searchManufacturer = (event) => {
    ManufacturerListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setManufacturers(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const unitName = (id) => {
    if (units && units[0]) {
      let unitName = units.filter(item => item.id === id)
      return unitName[0].name
    }
  }

  useEffect(() => {
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setSelectedManufacturer(props.initialValues.manufacturerName)
      setSelectedPartner(props.initialValues.primarySupplierName)
      let primarySupplierId
      let manufacturerId
      if (props.initialValues.primarySupplier && props.initialValues.primarySupplier.id) {
        primarySupplierId = props.initialValues.primarySupplier.id
      }
      if (props.initialValues.manufacturer && props.initialValues.manufacturer.id) {
        manufacturerId = props.initialValues.manufacturer.id
      }
      let _formData = {
        ...props.initialValues,
        primarySupplier: {id: primarySupplierId},
        manufacturer: {id: manufacturerId},
      }
      setFormData(_formData)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Termék név</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Me. egység</label>
                  <span className="p-input-icon-right">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value},
                                              unitName: unitName(e.target.value)
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value},
                                              unitName: unitName(e.target.value)
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="upc" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="upc"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Gyártó cikkszám (EAN/UPC)</label>
                  <span className="p-input-icon-right">
                      <InputText id="upc" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     upc: e.target.value
                                   })
                                 }}
                                 value={formData.upc}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="sku" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="sku"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Termék cikkszám (SKU)</label>
                  <span className="p-input-icon-right">
                      <InputText id="sku" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     sku: e.target.value
                                   })
                                 }}
                                 value={formData.sku}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="manufacturer" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="manufacturer"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Gyártó</label>
                  <span className="p-input-icon-right">
                    <AutoComplete value={selectedManufacturer}
                                  id={"manufacturer"}
                                  suggestions={manufacturers}
                                  forceSelection
                                  completeMethod={searchManufacturer}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      manufacturer: {id: e.value.id},
                                      manufacturerName: e.value.name
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      manufacturer: {id: null},
                                      manufacturerName: null
                                    })
                                    setSelectedManufacturer(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedManufacturer('')
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-th-large"}></i> Készlet adatok
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="primarySupplier" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="primarySupplier"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Elsődleges szállító</label>
                  <span className="p-input-icon-right">
                    <AutoComplete value={selectedPartner}
                                  id={"primarySupplier"}
                                  suggestions={partners}
                                  forceSelection
                                  completeMethod={searchPartner}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      primarySupplier: {id: e.value.id},
                                      primarySupplierName: e.value.name
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      primarySupplier: {id: null},
                                      primarySupplierName: null
                                    })
                                    setSelectedPartner(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPartner('')
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-lg-4"}>*/}
            {/*  <Field name="averagePurchaseUnitPrice" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="averagePurchaseUnitPrice"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Átlag nettó beszer. ért.</label>*/}
            {/*      <span className="p-input-icon-right">*/}
            {/*          <InputNumber {...input}*/}
            {/*                       id="averagePurchaseUnitPrice"*/}
            {/*                       locale="hu-HU"*/}
            {/*                       minFractionDigits={0}*/}
            {/*                       maxFractionDigits={9}*/}
            {/*                       value={formData.averagePurchaseUnitPrice}*/}
            {/*                       onChange={(e) => {*/}
            {/*                         setFormData({*/}
            {/*                           ...formData,*/}
            {/*                           averagePurchaseUnitPrice: e.value*/}
            {/*                         })*/}
            {/*                       }}*/}
            {/*                       className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <Field name="purchaseTypeProject" render={({input, meta}) => (
                <div className="p-grid">
                  <div className={"p-col-6 p-lg-1 p-xl-1"}>
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.purchaseTypeProject}
                      value={formData.purchaseTypeProject}
                      onChange={e => setFormData({...formData, purchaseTypeProject: e.target.value})}/>
                  </div>
                  <div className={"p-col-6 p-lg-11 p-xl-11 p-col-align-center"}>
                    <label htmlFor="purchaseTypeProject"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Projektre beszerzett
                      anyagok</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <Field name="purchaseTypeStockProject" render={({input, meta}) => (
                <div className="p-grid">
                  <div className={"p-col-6 p-lg-1 p-xl-1"}>
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.purchaseTypeStockProject}
                      value={formData.purchaseTypeStockProject}
                      onChange={e => setFormData({...formData, purchaseTypeStockProject: e.target.value})}/>
                  </div>
                  <div className={"p-col-6 p-lg-11 p-xl-11 p-col-align-center"}>
                    <label htmlFor="purchaseTypeStockProject"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Készletezett anyagok projektek,
                      garanciális javítások végrehajtásához</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <Field name="purchaseTypeStockOwn" render={({input, meta}) => (
                <div className="p-grid">
                  <div className={"p-col-6 p-lg-1 p-xl-1"}>
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.purchaseTypeStockOwn}
                      value={formData.purchaseTypeStockOwn}
                      onChange={e => setFormData({...formData, purchaseTypeStockOwn: e.target.value})}/>
                  </div>
                  <div className={"p-col-6 p-lg-11 p-xl-11 p-col-align-center"}>
                    <label htmlFor="purchaseTypeStockOwn"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Készlet saját
                      felhasználásra</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                    className="p-button-success"/>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProductFormComponent);
