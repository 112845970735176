import axios from "axios";

export default function CompanytypeListService(query) {
  if(query && query.toString().includes(',"multiSortMeta":[]')) {
    query = query.replace(',"multiSortMeta":[]', '')
  }

  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/company_type?" + query,
    params: {},
  });
}
