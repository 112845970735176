import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {Dropdown} from "primereact/dropdown";
import currencyFormatter from "currency-formatter";
import {Button} from "primereact/button";
import {TabView, TabPanel} from 'primereact/tabview';
import {Panel} from "primereact/panel";
import {SplitButton} from "primereact/splitbutton";
import { Badge } from 'primereact/badge';

import OrderShowService from "../../services/order/OrderShowService";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import OrderCreateReceiptService from "../../services/order/OrderCreateReceiptService";


const OrderShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [receiptGroups, setReceiptGroups] = useState([]);

  // outgoing receipts

  const [outgoingReceiptsLoading, setOutgoingReceiptsLoading] = useState(false);
  const [outgoingReceiptsTotalRecords, setOutgoingReceiptsTotalRecords] = useState(0);
  const [outgoingReceiptsRows, setOutgoingReceiptsRows] = useState(10);
  const [outgoingReceipts, setOutgoingReceipts] = useState([]);

  let _outgoingReceiptsLazyParams = {
    filters: {
      order: {value: props.initialValues.id},
    },
    first: 0,
    rows: outgoingReceiptsRows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  }
  const [outgoingReceiptsLazyParams, setOutgoingReceiptsLazyParams] = useState(_outgoingReceiptsLazyParams);

  const outgoingReceiptsDt = useRef(null);

  let outgoingReceiptsLoadLazyTimeout = null;
  const outgoingReceiptsLoadLazyData = () => {
    setOutgoingReceiptsLoading(true);
    if (outgoingReceiptsLoadLazyTimeout) {
      clearTimeout(outgoingReceiptsLoadLazyTimeout);
    }
    outgoingReceiptsLoadLazyTimeout = setTimeout(() => {
      window.App.setState({
        rerenderReceiptList: false,
        rerenderOrderShow: false,
      })
      ReceiptListService(JSON.stringify(outgoingReceiptsLazyParams))
        .then(response => {
          setOutgoingReceiptsTotalRecords(response.data.total_item_count);
          setOutgoingReceipts(response.data.items);
          setOutgoingReceiptsLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setOutgoingReceiptsLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const outgoingReceiptsOnPage = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_lazyParams);
  }
  const outgoingReceiptsOnSort = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsOnFilter = (event) => {
    let _outgoingReceiptsLazyParams = {...outgoingReceiptsLazyParams, ...event};
    _outgoingReceiptsLazyParams['first'] = 0;
    _outgoingReceiptsLazyParams['page'] = 0;
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setOutgoingReceiptsRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const outgoingReceiptsActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
        props.view !== 'purchaseorder' &&
        <>
          {rowData.draft === true ? (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                      tooltip="Módosítás"
                      onClick={() => {
                        // let _receiptType = 'invoice'
                        // if (rowData.receiptType === 'invoice' && rowData.outgoing === false) {
                        //   _receiptType = 'cost'
                        // }
                        // let _rowData = {
                        //   ...rowData,
                        //   receiptType: _receiptType
                        // }
                        // window.App.setState({
                        //   sidebarReceiptUpdate: true,
                        //   props: _rowData
                        // })
                        window.App.setState({
                          sidebarReceiptUpdate: true,
                          props: rowData
                        })
                      }}/>
            </>
          ) : (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm p-button-warning p-mr-2 disabled"
                      tooltip="Módosítás"/>
            </>
          )}
        </>
        }
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  useEffect(() => {
    outgoingReceiptsLoadLazyData();
  }, [outgoingReceiptsLazyParams, window.App.state.rerenderReceiptList === true])

  useEffect(() => {
    setLoading(true)
    OrderShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        setLoading(false)
        ReceiptgroupListService(JSON.stringify({
          filters: {
            receiptType: {
              value: 'invoice'
            },
            outgoing: {
              value: true
            },
            external: {
              value: false
            }
          }
        }))
          .then(response => {
            let _receiptGroups = []
            response.data.items.map(function (item, index) {
              if (item) {
                _receiptGroups.push({
                  label: item.name,
                  icon: 'pi pi-plus',
                  command: () => {
                    setLoading(true)
                    OrderCreateReceiptService(props.initialValues.id, item.id)
                      .then(response => {
                        setLoading(false)
                        window.App.setState({
                          props: {
                            id: response.data.receiptId,
                            outgoing: item.outgoing,
                            external: item.external,
                            receiptType: item.type
                          },
                          sidebarReceiptUpdate: true
                        })
                        setTimeout(() => {
                          window.App.setState({
                            rerenderOrderList: true,
                            rerenderReceiptList: true,
                          })
                        },1000)
                      })
                      .catch(error => {
                        setLoading(false)
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        setTimeout(() => {
                          window.App.setState({
                            rerenderOrderList: true,
                            rerenderReceiptList: true,
                          })
                        },1000)
                      })
                  }
                })
              }
            })
            setReceiptGroups(_receiptGroups)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }, [window.App.state.rerenderOrderShow === true])

  return (
    <>
      {loading === true ? (
        <>
          <span className={"p-d-block p-py-4"} style={{fontSize: '1.5em'}}>
            <i className="pi pi-spin pi-spinner" style={{fontSize: '0.9em'}}></i>
            &nbsp;Betöltés...
          </span>
        </>
      ) : (
        <>
          <h2 className={"text-primary p-mb-0"}>Megrendelés megtekintése</h2>
          <div className={"p-grid"}>
            {formData.orderNumber &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>
                Megrendelés száma
              </small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                <>{formData.orderNumber}</>
              </p>
            </div>
            }
          </div>
          <hr/>
          <TabView className="tabview-header-icon menu">
            <TabPanel header="Alap adatok" leftIcon="pi pi-chart-bar">
              <Panel>
                <div className={"p-grid"}>
                  {formData.orderDate &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Megrendelve</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.orderDate).local().format('YYYY-MM-DD HH:mm:ss')}
                    </p>
                  </div>
                  }
                  {formData.companyName &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Cég</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.companyName}
                    </p>
                  </div>
                  }
                  {formData.paymentMethodName &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Fizetés</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.isPaid === true ? (
                        <i className={"pi pi-check text-success"}></i>
                      ) : (
                        <i className={"pi pi-times text-danger"}></i>
                      )} {formData.paymentMethodName}
                    </p>
                  </div>
                  }
                  {formData.currency &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Pénznem</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.currency}
                    </p>
                  </div>
                  }
                  {formData.note && formData.note !== null &&
                  <div className={"p-col-12"}>
                    <small className={"p-text-uppercase"}>Megjegyzés</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.note.toString()}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-info-circle"}></i> Partner
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {formData.partnerName &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Név</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.partnerName}
                    </p>
                  </div>
                  }
                  {formData.partnerAddressDetail &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Számlázási cím</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.partnerAddressPostalCode} {formData.partnerAddressCity} {formData.partnerAddressDetail}
                    </p>
                  </div>
                  }
                  {formData.partnerShippingAddressDetail &&
                  <div className={"p-col"}>
                    <small className={"p-text-uppercase"}>Szállítási cím</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.partnerShippingAddressPostalCode} {formData.partnerShippingAddressCity} {formData.partnerShippingAddressDetail}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-list"}></i> Tételek
                    </h3>
                  </div>
                </div>
                {formData.items && formData.items[0] ? (
                  <DataTable
                    emptyMessage="Nincs találat."
                    value={formData.items}
                    autoLayout={true}
                    responsiveLayout="scroll"
                    stripedRows
                    dataKey="id"
                  >
                    <Column field="description" header="Leírás" sortable></Column>
                    <Column field="quantity" header="Mennyiség" sortable></Column>
                    <Column field="unitName" header="Egység" sortable></Column>
                    <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                            body={(rowData) => {
                              let currency
                              if (rowData.currency === 'HUF') {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: ',',
                                  thousand: ' ',
                                }
                              } else {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: '.',
                                  thousand: ' ',
                                }
                              }
                              return (
                                <>
                                  {currencyFormatter.format(
                                    rowData.netAmount, {
                                      precision: currency.precision,
                                      symbol: currency.symbol,
                                      decimal: currency.decimal,
                                      thousand: currency.thousand,
                                    }
                                  )}&nbsp;{rowData.currency}
                                </>
                              )
                            }}></Column>
                    <Column field="vatAmount" header="ÁFA összeg" sortable className={"p-text-right p-text-nowrap"}
                            body={(rowData) => {
                              let currency
                              if (rowData.currency === 'HUF') {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: ',',
                                  thousand: ' ',
                                }
                              } else {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: '.',
                                  thousand: ' ',
                                }
                              }
                              return (
                                <>
                                  {currencyFormatter.format(
                                    rowData.vatAmount, {
                                      precision: currency.precision,
                                      symbol: currency.symbol,
                                      decimal: currency.decimal,
                                      thousand: currency.thousand,
                                    }
                                  )}&nbsp;{rowData.currency}
                                </>
                              )
                            }}></Column>
                    <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                            body={(rowData) => {
                              let currency
                              if (rowData.currency === 'HUF') {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: ',',
                                  thousand: ' ',
                                }
                              } else {
                                currency = {
                                  precision: 2,
                                  symbol: '',
                                  decimal: '.',
                                  thousand: ' ',
                                }
                              }
                              return (
                                <>
                                  {currencyFormatter.format(
                                    rowData.grossAmount, {
                                      precision: currency.precision,
                                      symbol: currency.symbol,
                                      decimal: currency.decimal,
                                      thousand: currency.thousand,
                                    }
                                  )}&nbsp;{rowData.currency}
                                </>
                              )
                            }}></Column>
                  </DataTable>
                ) : (
                  <p>Nincs termék tétel....</p>
                )}

              </Panel>
            </TabPanel>
            <TabPanel header="Számlák" leftIcon="pi pi-list">
              <Panel>
                <DataTable
                  emptyMessage="Nincs találat."
                  value={outgoingReceipts} paginator first={outgoingReceiptsLazyParams.first}
                  totalRecords={outgoingReceiptsTotalRecords} onPage={outgoingReceiptsOnPage}
                  onSort={outgoingReceiptsOnSort} sortField={outgoingReceiptsLazyParams.sortField}
                  sortOrder={outgoingReceiptsLazyParams.sortOrder}
                  onFilter={outgoingReceiptsOnFilter} filters={outgoingReceiptsLazyParams.filters}
                  loading={outgoingReceiptsLoading} lazy
                  paginatorTemplate={outgoingReceiptsPager} rows={outgoingReceiptsRows} emptyMessage="Nincs találat."
                  autoLayout={true}
                  ref={outgoingReceiptsDt}
                  responsiveLayout="scroll"
                  stripedRows
                  dataKey="id"
                >
                  <Column body={outgoingReceiptsActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
                  <Column field="receiptType" header="Típus" body={(rowData) => {
                    if(rowData.receiptType === 'invoice') {
                      if(rowData.isStornoReceipt === true) {
                        return (
                          <>Sztornó számla</>
                        )
                      }
                      if(rowData.isReversedReceipt === true) {
                        return (
                          <>Sztornózott számla</>
                        )
                      }
                      else {
                        return (
                          <>Számla</>
                        )
                      }
                    }
                    if(rowData.receiptType === 'fee_request') {
                      return (
                        <>Díjbekérő</>
                      )
                    }
                    if(rowData.receiptType === 'delivery_note') {
                      return (
                        <>Szállítólevél</>
                      )
                    }
                  }}></Column>
                  <Column field="receiptNumber" header="Sorszám" body={(rowData) => {
                    if(rowData.draft === true){
                      return(
                        <>
                          <Badge value="Vázlat" severity="danger"></Badge>
                        </>
                      )
                    } else {
                      return(
                        <>{rowData.receiptNumber}</>
                      )
                    }
                  }}></Column>
                  <Column field="isPaid" header="Fizetve" body={(rowData) => {
                    return (
                      <>
                        {rowData.isPaid === true ? (
                          <>Igen<br/>{moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}</>
                        ) : (
                          <>Nem</>
                        )}
                      </>
                    )
                  }}></Column>
                  <Column field="partnerName" header="Partner"></Column>
                  <Column field="issueDate" header="Kelt" className={"p-text-nowrap"} body={(rowData) => {
                    return (
                      <>
                        {rowData.issueDate && rowData.issueDate !== null ? (
                          <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
                        ) : (
                          <></>
                        )
                        }
                      </>
                    )
                  }}></Column>
                  <Column field="deliveryDate" header="Teljesítés" className={"p-text-nowrap"}
                          body={(rowData) => {
                            return (
                              <>
                                {rowData.deliveryDate && rowData.deliveryDate !== null ? (
                                  <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
                                ) : (
                                  <></>
                                )
                                }
                              </>
                            )
                          }}></Column>
                  <Column field="paymentDate" header="Fiz. határidő" className={"p-text-nowrap"}
                          body={(rowData) => {
                            return (
                              <>
                                {rowData.paymentDate && rowData.paymentDate !== null ? (
                                  <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
                                ) : (
                                  <></>
                                )
                                }
                              </>
                            )
                          }}></Column>
                  <Column field="netAmount" header="Nettó összeg" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (rowData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.netAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{rowData.currency}
                              </>
                            )
                          }}></Column>
                  <Column field="grossAmount" header="Bruttó összeg" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (rowData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{rowData.currency}
                              </>
                            )
                          }}></Column>
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    {localStorage.getItem('roles').toString().includes('role_receipt_write') && receiptGroups && receiptGroups[0] && formData.partner && formData.partner !== null ? (
                      <SplitButton icon="pi pi-file" label="Új bizonylat létrehozása" model={receiptGroups}
                                   className={"p-button-info p-text-light p-ml-2"}/>
                      ):(
                      <SplitButton icon="pi pi-file" label="Új bizonylat létrehozása" disabled model={receiptGroups}
                                   className={"p-button-info p-text-light p-ml-2"}/>
                    )}
                  </div>
                </div>
              </Panel>
            </TabPanel>
          </TabView>
        </>
      )}
    </>
  )
}
export default withTranslation('common')(OrderShowComponent);
