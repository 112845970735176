import axios from "axios";

function UserLogoutService() {
  axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/logout",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
    .then(function (response) {

    })
    .catch(function (error) {

    })
  localStorage.removeItem('userToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('App-Network');
  localStorage.removeItem('networkName');
  localStorage.removeItem('roles');
  localStorage.removeItem('helpWelcome');
  Object.keys(localStorage)
    .filter(key => key.startsWith("module_"))
    .forEach(key => localStorage
      .removeItem((key))
    )
  window.App.setState({
    subscriptionMessages: []
  })
  Object.keys(localStorage)
    .filter(key => key.startsWith("subscriptionMessage_"))
    .forEach(key => localStorage
      .removeItem((key))
    )
  window.location.replace('/#/');
  window.App.forceUpdate()
}
export default UserLogoutService;
