import axios from "axios";

export default function GuestDocumentValidService(guestId, formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/guest/" + guestId + "/document_valid",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
