import axios from "axios";

export default function EmployeedocumentListService(query, employeeId) {
    if(query && query.toString().includes(',"multiSortMeta":[]')) {
    query = query.replace(',"multiSortMeta":[]', '')
  }

  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/employee/" + employeeId + "/documents?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
