import axios from "axios";

export default function OfferitemShowService(offerId, itemId) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/offer/" + offerId + "/item/" + itemId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
