import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import {Sidebar} from "primereact/sidebar";

import IdentifierListService from "../../services/identifier/IdentifierListService";
import IdentifierDeleteService from "../../services/identifier/IdentifierDeleteService";
import EmployeeShowComponent from "../employee/EmployeeViewComponent";
import GuestShowComponent from "../guest/GuestShowComponent";
import ProjectShowComponent from "../project/ProjectShowComponent";

const IdentifierListComponent = (props) => {

  const [identifier, setIdentifier] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderIdentifierList: false})
      IdentifierListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setIdentifier(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteIdentifier = (rowData) => {
    IdentifierDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_identifier_write') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarIdentifierUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {/*{localStorage.getItem('roles').toString().includes('role_identifier_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteIdentifier(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderIdentifierList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={identifier} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        stripedRows
        filterDisplay="row"
        responsiveLayout="scroll"
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="identifier" header="Azonosító" sortable filter filterPlaceholder="Azonosító"></Column>
        <Column field="serial" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"
                body={(rowData) => {
                  if (rowData.employee !== null && rowData.employee.id !== null) {
                    return (
                      <>
                        <a
                          onClick={() => {
                            window.App.setState({
                              sidebarEmployeeShow: true,
                              props: {id: rowData.employee.id}
                            })
                          }}
                        >{rowData.name} (Munkavállaló)</a>
                      </>
                    )
                  }
                  if (rowData.guest !== null && rowData.guest.id !== null) {
                    return (
                      <>
                        <a
                          onClick={() => {
                            window.App.setState({
                              sidebarGuestShow: true,
                              props: {id: rowData.guest.id}
                            })
                          }}
                        >{rowData.name} (Vendég)</a>
                      </>
                    )
                  }
                  if (rowData.project !== null && rowData.project.id !== null) {
                    return (
                      <>
                        <a
                          onClick={() => {
                            window.App.setState({
                              sidebarProjectShow: true,
                              props: {id: rowData.project.id}
                            })
                          }}
                        >{rowData.name} (Projekt)</a>
                      </>
                    )
                  }
                }}
        ></Column>
        <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
        <Column field="active" header="Aktív" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.active === true &&
              <i className={"pi pi-check text-success"}></i>
              }
              {rowData.active === false &&
              <i className={"pi pi-times text-danger"}></i>
              }
            </>
          )
        }}
        ></Column>
      </DataTable>
      <Sidebar position="left" visible={window.App.state.sidebarEmployeeShow} closeOnEscape={true}
               style={{width: '980px'}}
               onHide={() => {
                 window.App.setState({sidebarEmployeeShow: false})
               }}>
        <h2 className={"text-primary"}>Munkavállaló megtekintése</h2>
        <EmployeeShowComponent initialValues={window.App.state.props}/>
      </Sidebar>
      <Sidebar position="left" visible={window.App.state.sidebarGuestShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => {
                 window.App.setState({sidebarGuestShow: false})
               }}>
        <h2 className={"text-primary"}>Vendég megtekintése</h2>
        <GuestShowComponent initialValues={window.App.state.props}/>
      </Sidebar>
      <Sidebar position="left" visible={window.App.state.sidebarProjectShow} closeOnEscape={true}
               style={{width: '98%'}}
               onHide={() => {
                 window.App.setState({sidebarProjectShow: false})
               }}>
        <h2 className={"text-primary"}>Projekt megtekintése</h2>
        <ProjectShowComponent initialValues={window.App.state.props}/>
      </Sidebar>
    </>
  )
}
export default withTranslation('common')(IdentifierListComponent);
