import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import SafetyDownloadReportService from "../../services/safety/SafetyDownloadReportService";
import download from "js-file-download";
import moment from "moment";
import EntrylocationemployeeListService from "../../services/entrylocationemployee/EntrylocationemployeeListService";
import SupervisoryunitstatusListService
  from "../../services/supervisoryunitsyncstatus/SupervisoryunitstatusListService";
import EmployeeExportService from "../../services/employee/EmployeeExportService";
import SupervisoryUnitService from "../../services/report/SupervisoryUnitService";
import EntrylocationByNetworkShowService from "../../services/entrylocation/EntrylocationByNetworkShowService";

const EntrylocationemployeeListComponent = (props) => {

  const [currentEmployees, setCurrentEmployees] = useState([])
  const [entrylocation, setEntrylocation] = useState({})

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      EntrylocationemployeeListService(JSON.stringify(lazyParams))
        .then(response => {
          setCurrentEmployees(response.data.items);
          setTotalRecords(response.data.total_item_count);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [supervisoryunitStatuses, setSupervisoryunitStatuses] = useState([])
  const supervisoryunitStatus = (rowData) => {
    if (supervisoryunitStatuses && supervisoryunitStatuses[0]) {
      let status = supervisoryunitStatuses.filter(item => item.id === rowData.supervisoryUnitSyncStatus)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    EntrylocationByNetworkShowService(localStorage.getItem('networkId'))
      .then(response => {
        setEntrylocation(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    SupervisoryunitstatusListService()
      .then(response => {
        setSupervisoryunitStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat. Munkavállalók Projekthez rendelése a Projekt Munkatípusok alatt történik."
        value={currentEmployees}
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        paginator first={lazyParams.first}
        totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters}
        loading={loading} lazy
        paginatorTemplate={pager} rows={rows}
      >
        <Column body={(rowData) => {
          return (
            <React.Fragment>
              <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                      tooltip="Megtekintés"
                      onClick={() => {
                        let _rowData = {
                          ...rowData,
                          readOnly: true
                        }
                        window.App.setState({
                          sidebarEntrylocationemployeeShow: true,
                          props: _rowData,
                        })
                      }}/>
            </React.Fragment>
          )
        }}></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
        <Column field="employeePositionName" header="Beosztás" sortable filter filterPlaceholder="Beosztás"></Column>
        <Column field="supervisoryUnitSyncStatus" header="Üvegkapu státusz" sortable
                body={supervisoryunitStatus}></Column>
        <Column field="createdAt" header="Hozzárendelés ideje" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-text-right"}>
          {localStorage.getItem('roles').toString().includes('role_occ_safety_download') &&
          <Button className="p-button-secondary p-button-highlighted p-ml-2"
                  value={"Projekt kimutatás letöltése"}
                  type="button"
                  label={"Munkavédelmi bejegyzések kimutatás"}
                  icon={"pi pi-download"}
                  loading={window.App.state.loadingSafetyDownloadReport}
                  onClick={() => {
                    window.App.setState({loadingSafetyDownloadReport: true})
                    SafetyDownloadReportService()
                      .then(response => {
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          + 'munkaugyi-bejegyzesek-kimutatas'
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.xlsx')
                        window.App.setState({loadingSafetyDownloadReport: false})
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        window.App.setState({loadingSafetyDownloadReport: false})
                      })
                  }}/>
          }
          {process.env.REACT_APP_NETWORK_SEPARATION === 'ENTRY_LOCATION' &&
          <>
            {localStorage.getItem('roles').toString().includes('role_supervisor_unit_sync_report') && entrylocation && entrylocation.supervised && entrylocation.supervised === true ? (
              <Button type="button"
                      label="Üvegkapu jelentés"
                      icon={"pi pi-download"}
                      loading={loading}
                      onClick={() => {
                        setLoading(true)
                        SupervisoryUnitService()
                          .then(response => {
                            download(response.data, localStorage.getItem('networkName')
                              + '_'
                              + 'uvegkapu-report'
                              + '_'
                              + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                              + '.xlsx')
                            setLoading(false)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            setLoading(false)
                          })
                      }}
                      className="p-button-info p-button-highlighted p-ml-2"/>
            ) : (
              <>
                <Button type="button"
                        label="Üvegkapu jelentés"
                        icon={"pi pi-download"}
                        loading={loading}
                        disabled
                        className="p-button-info p-button-highlighted p-ml-2"/>
              </>
            )}
          </>
          }
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(EntrylocationemployeeListComponent);
