import axios from "axios";

export default function PurchaseorderrequestItemDeleteService(requestId, itemId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/purchase_order_request/" + requestId + "/" + itemId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
