import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import WarehouseListService from "../../services/warehouse/WarehouseListService";
import WarehouseDeleteService from "../../services/warehouse/WarehouseDeleteService";

const WarehouseListComponent = (props) => {

  const [warehouse, setWarehouse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderWarehouseList: false})
      WarehouseListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setWarehouse(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteWarehouse = (rowData) => {
    WarehouseDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_warehouse_write') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarWarehouseUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteWarehouse(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderWarehouseList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={warehouse} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="addressCountryCode" header="Kód" sortable></Column>
      <Column field="addressPostalCode" header="IRSZ" sortable></Column>
      <Column field="addressCity" header="Város" sortable></Column>
      <Column field="addressDetail" header="Cím" sortable></Column>
    </DataTable>

  )
}
export default withTranslation('common')(WarehouseListComponent);
