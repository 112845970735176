import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {FileUpload} from "primereact/fileupload";

import ProjectitemImportService from "../../services/project/ProjectitemImportService";

const ProjectitemImportComponent = (props) => {

  //default form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ProjectitemImportService(formData, props.projectId)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setFormData({...formData, xls: null})
        window.App.setState({
          sidebarReceiptCostImport: false,
          rerenderReceiptList: true,
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, '')
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <Field name="file" render={({input, meta}) => (
              <div className="p-field">
                {!formData.file ? (
                  <>
                    <label htmlFor="image"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                    <span className="">
                               <FileUpload mode="basic"
                                           name="file"
                                           id={"file"}
                                           accept=""
                                           auto="true"
                                           maxFileSize={1000000}
                                           customUpload
                                           uploadHandler={fileUpload}
                                           chooseLabel={"Fájl kiválasztása"}
                                           uploadLabel={"Feltöltés"}
                               />
                            </span>
                  </>
                ) : (
                  <>
                    <label htmlFor="image"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                      törlése</label>
                    <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger"
                                      onClick={() => setFormData({...formData, file: null})}
                              >Törlés</Button>
                            </span>
                  </>
                )}
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            {formData.file ? (
              <Button type="submit" label="Import indítása"  icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            ) : (
              <Button type="button" label="Import indítása"  icon={"pi pi-check"}
                      className="p-button-success"
                      onClick={() => {
                        alert('Tölts fel egy XLS fájlt!')
                      }}
              />
            )}
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectitemImportComponent);
