import axios from "axios";

export default function SafetyeducationParticipantRemoveService(formData, educationId) {
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_HOST + "/safety/education/" + educationId + "/participants",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
