import axios from "axios";

export default function UserPasswordSetService(formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/password_reminder",
    data: formData,
    params: {},
    headers: {}
  });
}
