import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import download from "js-file-download";
import moment from "moment";

import ProjectitemListService from "../../services/project/ProjectitemListService";
import ProductionitemListComponent from "../productionitem/ProductionitemListComponent";
import ProjectItemDownloadService from "../../services/project/ProjectItemDownloadService";
import ProjectitemSchemaService from "../../services/project/ProjectitemSchemaService";
import ProjectproductListComponent from "./ProjectproductListComponent";
import ProjectproductFormComponent from "./ProjectproductFormComponent";
import {Dialog} from "primereact/dialog";
import {Sidebar} from "primereact/sidebar";

const ProjectitemListComponent = (props) => {

  const [projectitems, setProjectitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'position',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectitemList: false})
      ProjectitemListService(JSON.stringify(lazyParams), props.projectId)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProjectitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteProjectitem = (rowData) => {
    // ProjectitemDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectitemUpdate: true,
                    dialogProjectitem: true,
                    projectitemFormMethod: 'update',
                    projectitemRowData: rowData
                  })
                }}/>
        <Button icon="pi pi-download" className="p-button-sm  p-button p-mr-2"
                tooltip="Letöltés"
                onClick={() => {
                  ProjectItemDownloadService(props.projectId, rowData.id)
                    .then(response => {
                      window.App.toastShow('success', 'Sikeres letöltés!', '')
                      download(response.data, localStorage.getItem('networkName')
                        + '_'
                        +
                        rowData.projectName
                        + '_'
                        +
                        rowData.name
                        + '_'
                        + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                        + '.pdf')
                      setLoading(false)
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      setLoading(false)
                    })
                }}/>
        <Button icon="pi pi-clock" className="p-button-sm  p-button-info p-mr-2"
                tooltip="Munkaidő"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectitemJobsList: true,
                    projectitemRowData: rowData
                  })
                }}
                />
      </React.Fragment>
    );
  }

  const [receipttypes, setReceipttypes] = useState([]);
  const receipttypeAction = (rowData) => {
    if (receipttypes && receipttypes[0]) {
      let receipttype = receipttypes.filter(item => item.id === rowData.receiptType)
      if (receipttype && receipttype[0]) {
        return receipttype[0].name
      }
    }
  }

  const [expandedRows, setExpandedRows] = useState(null);
  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <h3 className={"p-mt-0 p-pt-0"}>Feladatok</h3>
          <ProductionitemListComponent projectitemId={data.id} />
          <h3 className={"p-mt-0 p-pt-0"}>Alapanyagok</h3>
          <ProjectproductListComponent projectId={props.projectId} projectitemId={data.id} />
        </div>
      </>
    );
  }

  const [schemas, setSchemas] = useState([]);
  const schema = (rowData) => {
    if (schemas && schemas[0]) {
      let schema = schemas.filter(item => item.id === rowData.projectItemSchema)
      if (schema[0] && schema[0].name) {
        return schema[0].name
      }
    }
  }

  useEffect(() => {
    ProjectitemSchemaService()
      .then(response => {
        setSchemas(response.data)
      })
      .catch(error => {

      })

    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProjectitemList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={projectitems} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowExpand={(e) => {
          let id = e.data.id
          setExpandedRows({[id]: true})
          window.App.setState({
            valueProjectitemId: e.data.id
          })
        }}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="serial" header="Sorszám"></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="projectItemSchema" header="Tétel és díjszámítás típusa" sortable body={schema}></Column>
        <Column field="quantity" header="Mennyiség"></Column>
        <Column field="unitName" header="Egység"></Column>
        <Column field="unitPrice" header="Egységár"></Column>
        <Column expander style={{width: '3em'}}/>
      </DataTable>
      <Dialog visible={window.App.state.dialogProjectproduct}
              style={{width: '750px'}}
              header="Új alapanyag"
              modal
              onHide={() => {
                window.App.setState({
                  dialogProjectproduct: false
                })
              }}>
        <ProjectproductFormComponent projectId={props.projectId} projectItemId={window.App.state.valueProjectitemId}
                                     initialValues={window.App.state.projectproductRowData}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ProjectitemListComponent);
