import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Tooltip} from "primereact/tooltip";
import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {Message} from "primereact/message";
import {FileUpload} from "primereact/fileupload";
import {addLocale} from "primereact/api";

import EmployeeShowService from "../../services/employee/EmployeeShowService";
import IdentitydocumenttemplateListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import EmployeeInviteService from "../../services/employee/EmployeeInviteService";
import EntrycheckFormComponent from "../entrycheck/EntrycheckFormComponent";
import EmployeedocumentListService from "../../services/employeedocument/EmployeedocumentListService";
import EmployeedocumentAddService from "../../services/employeedocument/EmployeedocumentAddService";
import DocumenttemplateListService from "../../services/documenttemplate/DocumenttemplateListService";
import EntrylocationemployeeShowService from "../../services/entrylocationemployee/EntrylocationemployeeShowService";
import EntrylocationemployeeDocumentsService
  from "../../services/entrylocationemployee/EntrylocationemployeeDocumentsService";
import EntrylocationemployeeSafetyService
  from "../../services/entrylocationemployee/EntrylocationemployeeSafetyService";

const EntrylocationemployeeShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [identitydocumenttypes, setIdentitydocumenttypes] = useState({});
  const [safeties, setSafeties] = useState([]);
  const [safetytypes, setSafetytypes] = useState([]);
  const [documentImgDialog, setDocumentImgDialog] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [wildcards, setWildcards] = useState([]);
  const [wildcardsAdded, setWildcardsAdded] = useState([]);
  const [wildcardsDialog, setWildcardsDialog] = useState(false);

  const identityDocumentType = (id) => {
    if (identitydocumenttypes && identitydocumenttypes[0]) {
      let identitydocumenttype = identitydocumenttypes.filter(item => item.id === id)
      if (identitydocumenttype && identitydocumenttype[0] && identitydocumenttype[0].name) {
        return identitydocumenttype[0].name
      }
    }
  }
  const safetyType = (id) => {
    if (safetytypes && safetytypes[0]) {
      let safetytype = safetytypes.filter(item => item.id === Number(id))
      if (safetytype && safetytype[0] && safetytype[0].value) {
        return safetytype[0].value
      }
    }
  }

  // document upload
  const documentUploader = useRef(null);
  const documentUpload = (files) => {
    let filesToUpload = []
    files.files.map(function (file, index) {
      var reader = new FileReader();
      let name = file.name
      reader.onloadend = () => {
        var base64data = reader.result;
        filesToUpload.push({
          name: name,
          originalFileName: name,
          file: base64data
        })
      }
      reader.readAsDataURL(file)
      if(files.files.length == index + 1) {
        setTimeout(function() {
          EmployeedocumentAddService(formData.id, filesToUpload)
            .then(response => {
              window.App.toastShow('success', 'Sikeres feltöltés!');
              //documentUploader.clear()
              EmployeedocumentListService(null, props.initialValues.id)
                .then(response => {
                  setDocuments(response.data.items)
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                })
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        },500)
      }
    })
  }

  // documenttemplate autocomplete
  const [documenttemplates, setDocumenttemplates] = useState([]);
  const [selectedDocumenttemplate, setSelectedDocumenttemplate] = useState();
  const [selectedDocumenttemplateId, setSelectedDocumenttemplateId] = useState();

  const searchDocumenttemplate = (event) => {
    setSelectedDocumenttemplate(event.query.replace('Nincs találat', '').replace(': ', ''))
    DocumenttemplateListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setDocumenttemplates(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setDocumenttemplates(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedDocumenttemplate(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    EntrylocationemployeeDocumentsService(null, props.initialValues.id)
      .then(response => {
        setDocuments(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    SafetyTypeListService()
      .then(response => {
        setSafetytypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EntrylocationemployeeShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        EntrylocationemployeeSafetyService(props.initialValues.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentitydocumenttemplateListService()
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderEmployeeShow === true])

  return (
    <>
      <Panel className={""}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <div className={"p-grid p-mt-0"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Alapadatok
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              {formData.name &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <label htmlFor="name">Név</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.name}
                </p>
              </div>
              }
              {formData.active &&
              <div className={"p-col-12 p-md-3 p-lg-3"}>
                <label>Aktív</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.active === true ? (
                    <span className={"text-success"}><i className={"pi pi-check"}></i> Igen</span>
                  ) : (
                    <span className={"text-danger"}><i className={"pi pi-times"}></i> Nem</span>
                  )}
                </p>
              </div>
              }
              <div className={"p-col-12 p-md-3 p-lg-3"}>
                <label>Üvegkapu</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.supervised && formData.supervised === true ? (
                    <span className={"text-success"}><i className={"pi pi-check"}></i> Igen</span>
                  ) : (
                    <span className={"text-danger"}><i className={"pi pi-times"}></i> Nem</span>
                  )}
                </p>
              </div>
            </div>
            <div className={"p-grid p-mt-1"}>
              {formData.email &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <label htmlFor="email">Email</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.email}
                </p>
              </div>
              }
              {formData.phone &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <label htmlFor="email">Telefonszám</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.phone}
                </p>
              </div>
              }
            </div>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-sitemap"}></i> Foglalkoztatás
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              {formData.companyName &&
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <label>Cég</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.companyName}
                </p>
              </div>
              }
              {formData.employeePositionName &&
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <label>Beosztás</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.employeePositionName}
                </p>
              </div>
              }
              {formData.employmentWorkOrderName &&
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <label>Munkarend</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.employmentWorkOrderName}
                </p>
              </div>
              }
              {process.env.REACT_APP_EMPLOYEE_HOMEOFFICE_ENABLED === 'true' &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <label>Otthoni munkavégzés engedélyezve</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.isHomeofficeEnabled === true ? (
                    <>igen</>
                  ) : (
                    <>nem</>
                  )}
                </p>
              </div>
              }
            </div>
            {(formData.birthPlace || formData.birthDay || formData.employeeNationalityName || formData.identityDocumentType || formData.identityDocumentNumber || formData.taxNumber || formData.insuranceNumber) &&
            <>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-user"}></i> Személyes adatok
                  </h3>
                </div>
              </div>
              {(formData.birthPlace || formData.birthDay) &&
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <label>Született</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.birthPlace && <>{formData.birthPlace}, </>}{formData.birthDay && <>{moment.utc(formData.birthDay).local().format('YYYY-MM-DD')}</>}
                  </p>
                </div>
                {formData.employeeNationalityName &&
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <label>Állampolgárság</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.employeeNationalityName}
                  </p>
                </div>
                }
              </div>
              }
              <div className={"p-grid"}>
                {formData.identityDocumentType &&
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <label>Okmány típusa</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {identityDocumentType(formData.identityDocumentType)}
                  </p>
                </div>
                }
                {formData.identityDocumentNumber &&
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <label>Okmány azonosító</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.identityDocumentNumber}
                  </p>
                </div>
                }
                {formData.identityDocumentValidUntil &&
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <label>Okmány lejárat</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {moment.utc(formData.identityDocumentValidUntil).local().format('YYYY-MM-DD')}
                  </p>
                </div>
                }
                {formData.taxNumber &&
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <label>Adóazonosító</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.taxNumber}
                  </p>
                </div>
                }
                {formData.insuranceNumber &&
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <label>TAJ</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.insuranceNumber}
                  </p>
                </div>
                }
              </div>
            </>
            }
          </div>
          <div className={"p-col p-lg-4 p-xl-4"}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-image"}></i> Fotó
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col"}>
                {formData.id &&
                <img src={process.env.REACT_APP_API_HOST + "/employee/profileImage/" + formData.id
                + "?token=" + localStorage.getItem("userToken") + "&date=" + new (Date)}
                     className={"w-100 p-d-block"}/>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            {localStorage.getItem('roles').toString().includes('role_employee_write') && props.initialValues.readOnly !== true &&
            formData.email &&
            <>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Button type="button" label="Meghívás" icon="pi pi-envelope" className="p-button-highlighted"
                          loading={window.App.state.loadingEmployeeInvite}
                          onClick={() => {
                            window.App.setState({loadingEmployeeInvite: true})
                            EmployeeInviteService(formData.id)
                              .then(response => {
                                window.App.toastShow('success', 'Sikeres meghívás!',);
                                window.App.setState({loadingEmployeeInvite: false})
                                EmployeeShowService(formData.id)
                                  .then(response => {
                                    setFormData(response.data)
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                window.App.setState({loadingEmployeeInvite: false})
                              })
                          }}
                  />
                </div>
                {formData && formData.invitedAt !== null &&
                <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                  Meghívó kiküldve: {moment.utc(formData.invitedAt).local().format('YYYY.MM.DD. HH:mm:ss')}
                </div>
                }
                {formData && formData.invitedAt === null &&
                <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                  Meghívó kiküldve: nem
                </div>
                }
              </div>
            </>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            {formData.id && localStorage.getItem('roles').toString().includes('role_employee_write') && props.initialValues.readOnly !== true &&
            <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                    onClick={() => {
                      window.App.setState({
                        sidebarEmployeeShow: false,
                        sidebarEmployeeUpdate: true,
                        props: formData
                      })
                    }}
            />
            }
          </div>
          {formData.id && localStorage.getItem('roles').toString().includes('role_employee_write') && props.initialValues.readOnly !== true &&
          <div className={"p-col-12"}>
            <Message className={"w-100 p-d-block"} severity="warn" text="
                A rendszer automatikus meghívót küld a munkavállalónak, amennyiben létrehozáskor az e-mail cím meg van adva.
              "></Message>
          </div>
          }
        </div>
      </Panel>
      {localStorage.getItem('roles').toString().includes('role_occ_safety_emp_read') &&
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Munkavédelmi bejegyzések
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <DataTable value={safeties}
                   emptyMessage="Nincs találat."
                   autoLayout={true}
                   filterDisplay="row"
                   responsiveLayout="scroll"
                   stripedRows
        >
          {/*<Column field="name" header="Megnevezés"></Column>*/}
          {/*<Column field="entryLocationName" header="Projekt"></Column>*/}
          <Column field="occupationalSafetyName" header="Munkavédelmi előírás"></Column>
          <Column field="validUntil" header="Érvényes" body={(rowData) => {
            if (rowData.validUntil) {
              return (
                <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
              )
            } else {
              return (
                <>Korlátlan</>
              )
            }
          }}></Column>
          <Column header="Típus" body={(rowData) => {
            return (
              <>{safetyType(rowData.type)}</>
            )
          }}></Column>
        </DataTable>
      </Panel>
      }
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Dokumentumok
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <DataTable value={documents}
                   emptyMessage="Nincs találat."
                   autoLayout={true}
                   filterDisplay="row"
                   responsiveLayout="scroll"
                   stripedRows
        >
          <Column field="name" header="Megnevezés"></Column>
          <Column field="note" header="Megjegyzés"></Column>
          <Column field="validUntil" header="Érvényes" body={(rowData) => {
            if (rowData.validUntil) {
              return (
                <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
              )
            } else {
              return (
                <>Korlátlan</>
              )
            }
          }}></Column>
          <Column field="filePath" header="Fájl" body={(rowData) => {
            return (
              <>
                <a href={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id
                + "?token=" + localStorage.getItem('userToken') + "."
                + localStorage.getItem("networkId")}
                   target="_blank"
                >
                  <i className={"pi pi-file"}></i> {rowData.originalFileName}
                </a>
                {/*<img*/}
                {/*  src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id*/}
                {/*  + "?token=" + localStorage.getItem('userToken') + "."*/}
                {/*  + localStorage.getItem("networkId") + '&date=' + new Date()} className={"w-100"}*/}
                {/*  onClick={() => {*/}
                {/*    setDocumentImgDialog(true)*/}
                {/*    window.App.setState({*/}
                {/*      props: {*/}
                {/*        employeeId: formData.id,*/}
                {/*        imageId: rowData.id*/}
                {/*      }*/}
                {/*    })*/}
                {/*  }}*/}
                {/*  style={{width: '80px'}}*/}
                {/*/>*/}
              </>
            )
          }}></Column>
        </DataTable>
      </Panel>
      {/*{props.initialValues.readOnly !== true &&*/}
      {/*<Panel headerTemplate={*/}
      {/*  <div className={"p-grid"}>*/}
      {/*    <div className={"p-col-12"}>*/}
      {/*      <h3>*/}
      {/*        <i className={"pi pi-list"}></i> Azonosítók*/}
      {/*      </h3>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*} className={"p-mt-3"}>*/}
      {/*  <div className={"p-grid"}>*/}
      {/*    {identifiers && identifiers[0] ? (*/}
      {/*      [identifiers.map((identifier, index) => {*/}
      {/*        return (*/}
      {/*          <>*/}
      {/*            <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '200px'}}>*/}
      {/*              <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id*/}
      {/*              + "/?token=" + localStorage.getItem("userToken")*/}
      {/*              } className={"w-100"}/>*/}
      {/*                                {identifier.serial ? (*/}
      {/*              <h3>{identifier.serial}</h3>*/}
      {/*            ) : (*/}
      {/*              <h3>{identifier.identifier}</h3>*/}
      {/*            )}*/}

      {/*              {localStorage.getItem('api_host').toString().includes('test') &&*/}
      {/*              <Button type="button" onClick={() => {*/}
      {/*                window.App.setState({*/}
      {/*                  props: identifier.identifier,*/}
      {/*                  sidebarEntrycheck: true*/}
      {/*                })*/}
      {/*              }} label={"Teszt"}/>*/}
      {/*              }*/}
      {/*              {process.env.NODE_ENV === 'development' &&*/}
      {/*              <Button type="button" onClick={() => {*/}
      {/*                window.App.setState({*/}
      {/*                  props: identifier.identifier,*/}
      {/*                  sidebarEntrycheck: true*/}
      {/*                })*/}
      {/*              }} label={"Teszt"}/>*/}
      {/*              }*/}
      {/*            </div>*/}
      {/*          </>*/}
      {/*        )*/}
      {/*      })]*/}
      {/*    ) : (*/}
      {/*      <div className={"p-col-12"}>*/}
      {/*        Még nincs hozzárendelve azonosító vagy nincs jogosultság az azonosító megtekintéséhez.*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</Panel>*/}
      {/*}*/}
      <Dialog visible={window.App.state.sidebarEntrycheck}
              style={{width: '750px'}}
              header="Belépés tesztelése"
              modal
              onHide={() => {
                window.App.setState({sidebarEntrycheck: false})
              }}>
        <EntrycheckFormComponent identifier={window.App.state.props}/>
      </Dialog>
      <Dialog visible={documentImgDialog}
              style={{width: '750px'}}
              header="Dokumentum megtekintése"
              modal
              onHide={() => {
                setDocumentImgDialog(false)
              }}>
        <img src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + window.App.state.props.employeeId
        + "/download/" + window.App.state.props.imageId
        + "?token=" + localStorage.getItem("userToken") + "." + localStorage.getItem('networkId')}
             style={{'width': '700px', 'height': 'auto'}}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(EntrylocationemployeeShowComponent);
