import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Panel} from "primereact/panel";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import {MultiSelect} from 'primereact/multiselect';

import EntrylogListService from "../../services/entrylog/EntrylogListService";
import EntrylogActionListService from "../../services/entrylog/EntrylogActionListService";
import EntrylogStatusListService from "../../services/entrylog/EntrylogStatusListService";
import EntrylogCountService from "../../services/entrylog/EntrylogCountService";
import EntryzoneListService from "../../services/entryzone/EntryzoneListService";
import EntryterminalListService from "../../services/entryterminal/EntryterminalListService";
import EntrylocationByNetworkShowService from "../../services/entrylocation/EntrylocationByNetworkShowService";
import {Tooltip} from "primereact/tooltip";

const EntrylogLiveListComponent = (props) => {

  const [entrylog, setEntrylog] = useState([]);
  const [entrylogActions, setEntrylogActions] = useState([]);
  const [entrylogStatuses, setEntrylogStatuses] = useState([]);
  const [entrylogCount, setEntrylogCount] = useState('0');
  const [loading, setLoading] = useState(false);
  const [imgDialog, setImgDialog] = useState(false);
  const [terminalDialog, setTerminalDialog] = useState(false);
  const [entryLocation, setEntryLocation] = useState({});

  const [entryZones, setEntryZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');

  const [entryTerminals, setEntryTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState('');

  const entrylogStatus = (id) => {
    if (entrylogStatuses && entrylogStatuses[0]) {
      let entrylogStatus = entrylogStatuses.filter(item => item.id === id)
      if (entrylogStatus && entrylogStatus[0] && entrylogStatus[0].name) {
        return entrylogStatus[0].name
      }
    }
  }
  const entrylogAction = (id) => {
    if (entrylogActions && entrylogActions[0]) {
      let entrylogAction = entrylogActions.filter(item => item.id === id)
      if (entrylogAction && entrylogAction[0] && entrylogAction[0].name) {
        return entrylogAction[0].name
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    EntryzoneListService()
      .then(response => {
        setEntryZones(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EntrylogActionListService()
      .then(response => {
        setEntrylogActions(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EntrylogStatusListService()
      .then(response => {
        setEntrylogStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (

    <div className={"EntryzoneLiveList"}>
      <div className={"p-grid p-mb-3"}>
        <div className={"p-col-12 p-lg-4 p-xl-4 p-text-center"}>
          <p className={"p-m-0 p-p-0 p-text-center"}>
            Zóna kiválasztása
          </p>
          <Dropdown options={entryZones}
                    optionLabel="name"
                    optionValue="name"
                    id={"entryZones"}
                    emptyMessage={"Még nincs zóna..."}
                    onChange={(e) => {
                      setSelectedZone(e.value)
                      setSelectedTerminal(null)
                      EntryterminalListService('{"filters":{"entryZoneName":{"value": "' + e.value + '"}}}')
                        .then(response => {
                          setEntryTerminals(response.data.items)
                          EntrylocationByNetworkShowService(localStorage.getItem('networkId'))
                            .then(response => {
                              setEntryLocation(response.data)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    value={selectedZone}
          />
        </div>
        {entryTerminals && entryTerminals[0] &&
        <div className={"p-col-12 p-lg-4 p-xl-4 p-text-center"}>
          <p className={"p-m-0 p-p-0 p-text-center"}>
            Terminál(ok) kiválasztása
          </p>
          <MultiSelect options={entryTerminals}
                       optionLabel="name"
                       optionValue="id"
                       id={"entryZones"}
                       emptyMessage={"Még nincs zóna..."}
                       onChange={(e) => {
                         setSelectedTerminal(e.value)
                         EntrylogListService('{"sortField":"createdAt","sortOrder":1,' +
                           '"filters":{"createdAtFrom": {"value": "' + moment.utc(new Date()).subtract(60, "minutes").local().format('YYYY-MM-DD HH:mm:ss').toString() + '"}, "entryZoneName":{"value": "' + selectedZone + '"},"entryTerminalIds":{"value":"' + e.value + '"}}}')
                           .then(response => {
                             setEntrylog(response.data.items);
                             setLoading(false)
                           })
                           .catch(error => {
                             window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                           })
                         EntrylogCountService('{"filters":{"entryZoneName":{"value":"' + selectedZone + '"},"entryTerminalIds":{"value":"' + e.value + '"}}}')
                           .then(response => {
                             setEntrylogCount(response.data.count);
                           })
                           .catch(error => {
                             window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                           })
                         clearInterval(window.liveInterval)
                         window.liveInterval = setInterval(function () {
                           setLoading(true)
                           EntrylogListService('{"sortField":"createdAt","sortOrder":1,' +
                             '"filters":{"createdAtFrom": {"value": "' + moment.utc(new Date()).subtract(60, "minutes").local().format('YYYY-MM-DD HH:mm:ss').toString() + '"}, "entryZoneName":{"value":"' + selectedZone + '"},"entryTerminalIds":{"value":"' + e.value + '"}}}')
                             .then(response => {
                               setEntrylog(response.data.items);
                               setLoading(false)
                             })
                             .catch(error => {
                               window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                             })
                           EntrylogCountService('{"filters":{"entryZoneName":{"value": "' + selectedZone + '"},"entryTerminalIds":{"value":"' + e.value + '"}}}')
                             .then(response => {
                               setEntrylogCount(response.data.count);
                             })
                             .catch(error => {
                               window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                             })
                         }, 5000);
                       }}
                       value={selectedTerminal}
          />
        </div>
        }
        {loading !== true ? (
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <h1 className={"p-m-0 p-p-0 p-text-center"}>
              <small className={"p-d-block p-text-normal"}><small>Zónában tartózkodók</small></small>
              {entrylogCount} <small className={"p-text-normal"}>fő</small>
            </h1>
          </div>
        ) : (
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <h1 className={"p-m-0 p-p-0 p-text-center"}>
              <i className="pi pi-spin pi-spinner" style={{'fontSize': '2.1em'}}></i>
            </h1>
          </div>
        )}
      </div>
      {loading !== true && selectedZone && selectedZone[0] && selectedTerminal && selectedTerminal[0] && entrylog && !entrylog[0] &&
      <>
        {entryLocation && entryLocation.mode && entryLocation.mode === 'offline' ? (
          <p className={"p-text-center"}>
            Megszakadt a kapcsolat a helyszíni beléptető rendszerrel. Az utolsó szinkronizáció időpontja:
            {moment.utc(entryLocation.updatedAt).local().format('YYYY.MM.DD. HH:mm:ss')}
            <br />
            A Beléptetés zavartalanul működik, azonban a Belépési napló események csak a kapcsolat helyreállítását követően kerülnek szinkronizálásra. A Vendégkezelés az offline eljárásrend szerint üzemel. Az alábbi, beléptetést érintő módosítások csak a kapcsolat helyreállítását követően lépnek életbe:
            Zónák módosítása, Munkavédelmi előírások módosítása, Munkavállalók projekt munkatípushoz rendelése, Munkavállalók kitiltása a projektből
          </p>
        ) : (
          <p className={"p-text-center"}>Az utóbbi egy órában nem történt esemény.</p>
        )}
      </>
      }
      {entrylog.map((log, index) => {
          return (
            <>
              <Panel className={"p-mb-3 p-pb-2 status-" + log.status}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-2 p-xl-1 p-text-center p-col-align-center"}>
                    {log.status === 'UNID' &&
                    <i className={"pi pi-question"} style={{fontSize: '72px'}}></i>
                    }
                    {log.status === 'PASS' &&
                    <i className={"pi pi-check"} style={{fontSize: '72px'}}></i>
                    }
                    {log.status === 'BLCK' &&
                    <i className={"pi pi-times"} style={{fontSize: '72px'}}></i>
                    }
                    {log.status === 'STORNO' &&
                    <i className={"pi pi-times"} style={{fontSize: '72px'}}></i>
                    }
                  </div>
                  {log.employee !== null && log.employee.id ? (
                    <div className={"p-col-12 p-lg-3 p-xl-3 p-col-align-center p-text-center"}>
                      <img
                        src={process.env.REACT_APP_API_HOST + "/employee/profileImage/" + log.employee.id + "?date=" + new (Date)}
                        onClick={() => {
                          setImgDialog(true)
                          window.App.setState({props: log.employee.id})
                        }}
                      />
                    </div>
                  ) : (
                    <div className={"p-col-12 p-lg-3 p-xl-3 p-col-align-center"}>

                    </div>
                  )}
                  <div className={"p-col-12 p-lg-3 p-xl-3 p-col-align-center p-text-center"}>
                    <img src={process.env.REACT_APP_API_HOST + "/entry_log/image/" + log.id + "?date=" + new (Date)}
                         onClick={() => {
                           window.App.setState({props: log.id})
                           setTimeout(function () {
                             setTerminalDialog(true)
                           }, 500)
                         }}
                    />
                  </div>
                  <div className={"p-col"}>
                    <table>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Státusz:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {entrylogStatus(log.status)}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Időpont:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {moment.utc(log.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Irány:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {entrylogAction(log.action)}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Zóna:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {log.entryZoneName}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Terminál:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {log.entryTerminalName}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Vállalkozás:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {log.companyName}
                        </td>
                      </tr>
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Név:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {log.name}&nbsp;
                          {log.employee !== null && log.employee.id && <>(Munkavállaló)</>}
                          {log.guest !== null && log.guest.id && <>(Vendég)</>}
                        </td>
                      </tr>
                      {log.cause &&
                      <tr>
                        <td className={"p-bg-transparent"}>
                          <strong>Megjegyzés:</strong>
                        </td>
                        <td className={"p-bg-transparent"}>
                          {log.note}
                        </td>
                      </tr>
                      }
                    </table>
                  </div>
                </div>
              </Panel>
            </>
          )
        }
      )}
      <Dialog visible={imgDialog}
              style={{width: '750px'}}
              header="Személy megtekintése"
              modal
              onHide={() => {
                setImgDialog(false)
              }}>
        <img
          src={process.env.REACT_APP_API_HOST + "/employee/profileImage/" + window.App.state.props + "?date=" + new (Date)}
          style={{'width': '700px', 'height': 'auto'}}/>
      </Dialog>
      <Dialog visible={terminalDialog}
              style={{width: '750px'}}
              header="Terminál megtekintése"
              modal
              onHide={() => {
                setTerminalDialog(false)
              }}>
        <img src={process.env.REACT_APP_API_HOST + "/entry_log/image/" + window.App.state.props + "?date=" + new (Date)}
             style={{'width': '700px', 'height': 'auto'}}/>
      </Dialog>
    </div>

  )
}
export default withTranslation('common')(EntrylogLiveListComponent);
