import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputSwitch} from "primereact/inputswitch";
import {Calendar} from 'primereact/calendar';
import {addLocale} from 'primereact/api';
import {BlockUI} from "primereact/blockui";
import {PickList} from "primereact/picklist";
import {Dialog} from "primereact/dialog";
import {Messages} from "primereact/messages";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Dropdown} from "primereact/dropdown";

import SafetyeducationShowService from "../../services/safetyeducation/SafetyeducationShowService";
import SafetyeducationResultAddService from "../../services/safetyeducation/SafetyeducationResultAddService";
import SafetyeducationParticipantAddService from "../../services/safetyeducation/SafetyeducationParticipantAddService";
import SafetyeducationParticipantListService
  from "../../services/safetyeducation/SafetyeducationParticipantListService";
import EntrylocationEmployeeListService from "../../services/entrylocation/EntrylocationEmployeeListService";
import SafetyeducationResultListService from "../../services/safetyeducation/SafetyeducationResultListService";
import SafetyeducationResultDeleteService from "../../services/safetyeducation/SafetyeducationResultDeleteService";
import {InputMask} from "primereact/inputmask";

const SafetyeducationeducationResultsComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [resultDialog, setResultDialog] = useState(false);
  const [loadingResultValidate, setLoadingResultValidate] = useState(false);

  const [resultFormData, setResultFormData] = useState({}); // set on dialog open!

  const resultValidate = (data) => {
    let errors = {};
    if (!data.validUntil) {
      errors.validUntil = 'Kötelező mező';
    }
    if (!data.value) {
      errors.value = 'Kötelező mező';
    }
    return errors;
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const resultOnSubmit = (data, form) => {
    setLoadingResultValidate(true)
    let _employeesToAdd = []
    Object.entries(employeesToAdd).map(function (employee, index) {
      _employeesToAdd.push(employee[1].employee.id)
    })
    let _resultFormData = {
      validUntil: data.validUntil,
      value: data.value,
      employees: _employeesToAdd
    }
    SafetyeducationResultAddService(_resultFormData, props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        setResultDialog(false)
        loadLazyDataEmployeesSource(props.initialValues.id);
        loadLazyDataEmployeesTarget(props.initialValues.id);
        setLoadingResultValidate(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoadingResultValidate(false)
      })
  }


  // employees assign
  const [employeesSource, setEmployeesSource] = useState([]);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesTarget, setEmployeesTarget] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);

  const [loadingEmployeesSource, setLoadingEmployeesSource] = useState(false);
  const [totalRecordsEmployeesSource, setTotalRecordsEmployeesSource] = useState(0);
  const [rowsEmployeesSource, setRowsEmployeesSource] = useState(10);
  const [lazyParamsEmployeesSource, setLazyParamsEmployeesSource] = useState({
    first: 0,
    rows: rowsEmployeesSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesSource = null;
  const loadLazyDataEmployeesSource = (educationId) => {
    setLoadingEmployeesSource(true);
    if (loadLazyTimeoutEmployeesSource) {
      clearTimeout(loadLazyTimeoutEmployeesSource);
    }
    loadLazyTimeoutEmployeesSource = setTimeout(() => {
      if (props && props.initialValues.entryLocation && props.initialValues.entryLocation.id) {
        SafetyeducationParticipantListService(JSON.stringify(lazyParamsEmployeesSource), props.initialValues.id)
          .then(response => {
            setTotalRecordsEmployeesSource(response.data.total_item_count);
            setEmployeesSource(response.data.items);
            setLoadingEmployeesSource(false);
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      }
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onSortEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onFilterEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const pagerEmployeesSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [loadingEmployeesTarget, setLoadingEmployeesTarget] = useState(false);
  const [totalRecordsEmployeesTarget, setTotalRecordsEmployeesTarget] = useState(0);
  const [rowsEmployeesTarget, setRowsEmployeesTarget] = useState(10);
  const [lazyParamsEmployeesTarget, setLazyParamsEmployeesTarget] = useState({
    first: 0,
    rows: rowsEmployeesTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesTarget = null;
  const loadLazyDataEmployeesTarget = (educationId) => {
    setLoadingEmployeesTarget(true);
    if (loadLazyTimeoutEmployeesTarget) {
      clearTimeout(loadLazyTimeoutEmployeesTarget);
    }
    loadLazyTimeoutEmployeesTarget = setTimeout(() => {
      SafetyeducationResultListService(JSON.stringify(lazyParamsEmployeesTarget), educationId)
        .then(response => {
          setTotalRecordsEmployeesTarget(response.data.total_item_count);
          setEmployeesTarget(response.data.items);
          setLoadingEmployeesTarget(false);
          setEmployeesToAdd([])
          setEmployeesToDelete([])

        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onSortEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onFilterEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const pagerEmployeesTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const onEmployeeDelete = () => {
    window.App.setState({loadingSafetyeducationResultDelete: true})
    let _employeesToDelete = []
    Object.entries(employeesToDelete).map(function (employee, index) {
      _employeesToDelete.push(employee[1].employee.id)
    })
    SafetyeducationResultDeleteService(_employeesToDelete, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!');
        loadLazyDataEmployeesTarget(formData.id);
        loadLazyDataEmployeesSource(formData.id);
        window.App.setState({loadingSafetyeducationResultDelete: false})
        setEmployeesToAdd([])
        setEmployeesToDelete([])
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingSafetyeducationResultDelete: false})
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    if (props && props.initialValues) {
      SafetyeducationShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      loadLazyDataEmployeesSource(props.initialValues.id);
      loadLazyDataEmployeesTarget(props.initialValues.id);
    }
  }, [lazyParamsEmployeesSource, lazyParamsEmployeesTarget])

  return (
    <div className={"p-grid p-d-flex p-flex-wrap"}>
      <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex"}>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={"w-100"}>
          <div className={"p-grid"}>
            {formData.name &&
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <label>Megnevezés</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.name}
              </p>
            </div>
            }
            {/*{formData.occupationalSafetyName &&*/}
            {/*<div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
            {/*  <label>Munkavédelmi előírás</label>*/}
            {/*  <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
            {/*    {formData.occupationalSafetyName}*/}
            {/*  </p>*/}
            {/*</div>*/}
            {/*}*/}
            {formData.companyName &&
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <label>Szervező</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.companyName}
              </p>
            </div>
            }
            {formData.code &&
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <label>Kód</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.code}
              </p>
            </div>
            }
            {formData.description &&
            <div className={"p-col-8 p-lg-8 p-xl-8"}>
              <label>Leírás</label>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {formData.description}
              </p>
            </div>
            }
            {formData.entryLocationName &&
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <label>Projekt</label>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {formData.entryLocationName}
              </p>
            </div>
            }
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-circle"}></i> Részletek
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            {formData.length &&
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Időtartam</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.length}
              </p>
            </div>
            }
            {formData.location &&
            <div className={"p-col-12 p-md-8 p-lg-8"}>
              <label>Helyszín</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.location}
              </p>
            </div>
            }
            {formData.applyFrom &&
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Jelentkezés kezdete</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {moment.utc(formData.applyFrom).local().format('YYYY-MM-DD')}
              </p>
            </div>
            }
            {formData.applyUntil &&
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Jelentkezés vége</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {moment.utc(formData.applyUntil).local().format('YYYY-MM-DD')}
              </p>
            </div>
            }
            {formData.date &&
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Dátum</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {moment.utc(formData.date).local().format('YYYY-MM-DD HH:mm')}
              </p>
            </div>
            }
          </div>
          <div className={"p-grid"}>
            {formData.link &&
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <label>Link</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.link}
              </p>
            </div>
            }
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-info-circle"}></i> Tulajdonságok
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Online</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.isOnline === true ? (
                  <>Igen</>
                ) : (
                  <>Nem</>
                )}
              </p>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Lezárt</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.closed === true ? (
                  <>Igen</>
                ) : (
                  <>Nem</>
                )}
              </p>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <label>Vizsga</label>
              <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                {formData.hasExam === true ? (
                  <>Igen</>
                ) : (
                  <>Nem</>
                )}
              </p>
            </div>
          </div>
        </Panel>
      </div>
      <div className={"p-col-12 p-lg-8 p-xl-8 p-d-flex"}>
        <Panel className={"w-100"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Résztvevők
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable
                        emptyMessage="Nincs találat."
                        value={employeesSource} paginator first={lazyParamsEmployeesSource.first}
                        totalRecords={totalRecordsEmployeesSource} onPage={onPageEmployeesSource}
                        onSort={onSortEmployeesSource} sortField={lazyParamsEmployeesSource.sortField}
                        sortOrder={lazyParamsEmployeesSource.sortOrder}
                        onFilter={onFilterEmployeesSource} filters={lazyParamsEmployeesSource.filters}
                        loading={loadingEmployeesSource} lazy
                        paginatorTemplate={pagerEmployeesSource} rows={rowsEmployeesSource}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        selection={employeesToAdd} onSelectionChange={e => {
                        setEmployeesToAdd(e.value)
                      }} dataKey="id"
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        stripedRows
                        selectionMode="checkbox"
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="employeeName" header="Név" sortable filter
                                filterPlaceholder="Név"></Column>
                        <Column field="companyName" header="Vállalkozás" sortable filter
                                filterPlaceholder="Vállalkozás"></Column>

                      </DataTable>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right"}>
                          {employeesToAdd && employeesToAdd[0]
                          && localStorage.getItem('roles').toString().includes('role_occ_safety_edu_results') ? (
                            <Button type="button" label="Eredmény hozzáadása" icon={"pi pi-plus"}
                                    onClick={() => {
                                      setResultDialog(true)
                                    }}
                                    className="p-button-success"/>
                          ) : (
                            <Button type="button" label="Eredmény hozzáadása" icon={"pi pi-plus"}
                                    disabled
                                    className="p-button-success"/>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A jelentkezők kiválasztása csak mentés után
                      lehetséges!</>
                  )}
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Eredménnyel rendelkezők
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable
                        emptyMessage="Nincs találat."
                        value={employeesTarget} paginator first={lazyParamsEmployeesTarget.first}
                        totalRecords={totalRecordsEmployeesTarget} onPage={onPageEmployeesTarget}
                        onSort={onSortEmployeesTarget} sortField={lazyParamsEmployeesTarget.sortField}
                        sortOrder={lazyParamsEmployeesTarget.sortOrder}
                        onFilter={onFilterEmployeesTarget} filters={lazyParamsEmployeesTarget.filters}
                        loading={loadingEmployeesTarget} lazy
                        paginatorTemplate={pagerEmployeesSource} rows={rowsEmployeesTarget}
                        emptyMessage="Nincs találat."
                        selection={employeesToDelete} onSelectionChange={e => {
                        setEmployeesToDelete(e.value)
                      }} dataKey="id"
                        autoLayout={true}
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        stripedRows
                        selectionMode="checkbox"
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="employeeName" header="Név" sortable filter
                                filterPlaceholder="Név"></Column>
                        <Column field="companyName" header="Vállalkozás" sortable filter
                                filterPlaceholder="Vállalkozás"></Column>
                        <Column field="value" header="Eredmény" sortable filter
                                filterPlaceholder="Eredmény"></Column>
                        <Column field="validUntil" header="Érvényes" body={(rowData) => {
                          return (
                            <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
                          )
                        }}></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right"}>
                          {employeesToDelete && employeesToDelete[0] ? (
                            <Button type="button" label="Eredmény eltávolítása"
                                    loading={window.App.state.loadingSafetyeducationResultDelete}
                                    onClick={onEmployeeDelete}
                                    icon="pi pi-trash"
                                    className="p-button-danger"/>
                          ) : (
                            <Button type="button" label="Eredmény eltávolítása"
                                    disabled
                                    icon="pi pi-trash"
                                    className="p-button-danger"/>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A jelentkezők megtekintése csak mentés után
                      lehetséges!</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Panel>

        <Dialog visible={resultDialog}
                style={{width: '750px'}}
                header="Eredmény hozzáadása"
                modal
                onHide={() => {
                  setResultDialog(false)
                }}>
          <Form onSubmit={resultOnSubmit}
                initialValues={resultFormData}
                validate={resultValidate}
                render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-md-3 p-lg-3"}>
                        <Field name="validUntil" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="validUntil"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség
                              vége</label>
                            <span className="p-input-icon-right">
                              <InputMask id={"validUntil"}
                                         mask={"99:99"}
                                         placeholder={"OO:PP"}
                                         value={resultFormData.validUntil}
                                         onChange={(e) => {
                                           if (e.value.indexOf("_") === -1) {
                                             if (e.value === '') {
                                               e.value = null
                                             }
                                             setResultFormData({
                                               ...resultFormData,
                                               validUntil: e.value
                                             })
                                           }
                                         }}
                              />
                        </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-9 p-xl-9"}>
                        <Field name="value" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="value"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Eredmény</label>
                            <span className="p-input-icon-right">
                                <InputText id="value" {...input}
                                           onChange={(e) => {
                                             setResultFormData({
                                               ...resultFormData,
                                               value: e.target.value
                                             })
                                           }}
                                           value={resultFormData.value}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                              </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right"}>
                        <Button type="submit" className={"p-button-success"} label="Eredmény hozzáadása"
                                icon={"pi pi-plus"}
                                loading={loadingResultValidate}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
      </div>
    </div>

  )
}
export default withTranslation('common')(SafetyeducationeducationResultsComponent);
