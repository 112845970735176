import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import {Dropdown} from "primereact/dropdown";
import ReceiptDeliverynoteInvoiceService from "../../services/receipt/ReceiptDeliverynoteInvoiceService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import currencyFormatter from "currency-formatter";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import ReceiptParentAddService from "../../services/receipt/ReceiptParentAddService";
import ReceiptParentListService from "../../services/receipt/ReceiptParentListService";
import ReceiptParentRemoveService from "../../services/receipt/ReceiptParentRemoveService";

const ReceiptDeliverynoteInvoiceComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [receiptgroups, setReceiptgroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.receiptGroup) {
    //   errors.receiptGroup = 'Számlatömb megadása kötelező';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {

    setLoading(true)
    setFormData({
      ...formData,
      data
    });

    ReceiptDeliverynoteInvoiceService(window.App.state.props.id, formData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres számla létrehozás!');
        setLoading(false)
        window.App.setState({
          sidebarReceiptUpdate: true,
          dialogReceiptDeliverynoteInvoiceCreate: false,
          props: {
            id: response.data.receiptId,
            external: true,
            receiptType: 'invoice',
            outgoing: false,
          },
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // receipts assign
  const [receiptsSource, setReceiptsSource] = useState([]);
  const [receiptsToAdd, setReceiptsToAdd] = useState([]);

  const [loadingReceiptsSource, setLoadingReceiptsSource] = useState(false);
  const [totalRecordsReceiptsSource, setTotalRecordsReceiptsSource] = useState(0);
  const [rowsReceiptsSource, setRowsReceiptsSource] = useState(10);
  const [lazyParamsReceiptsSource, setLazyParamsReceiptsSource] = useState({
    first: 0,
    rows: rowsReceiptsSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutReceiptsSource = null;
  const loadLazyDataReceiptsSource = (worktypeId) => {
    setLoadingReceiptsSource(true);
    if (loadLazyTimeoutReceiptsSource) {
      clearTimeout(loadLazyTimeoutReceiptsSource);
    }
    loadLazyTimeoutReceiptsSource = setTimeout(() => {
      let _lazyParamsReceiptsSource = {
        ...lazyParamsReceiptsSource,
        filters: {
          ...lazyParamsReceiptsSource.filters,
          receiptType: {value: "invoice"}
        }
      }
      ReceiptListService(JSON.stringify(_lazyParamsReceiptsSource))
        .then(response => {
          setTotalRecordsReceiptsSource(response.data.total_item_count);
          setReceiptsSource(response.data.items);
          setLoadingReceiptsSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const onSortReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const onFilterReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const pagerReceiptsSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsReceiptsSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  // const onReceiptsSubmit = () => {
  //   window.App.setState({loadingReceiptParentAdd: true})
  //   let _receiptsToAdd = []
  //   Object.entries(receiptsToAdd).map(function (receipt, index) {
  //     _receiptsToAdd.push(receipt[1].id)
  //   })
  //   // ReceiptParentAddService(_receiptsToAdd, props.initialValues.id)
  //   //   .then(response => {
  //   //     window.App.toastShow('success', 'Sikeres mentés!');
  //   //     loadLazyDataReceiptsSource(props.initialValues.id);
  //   //     window.App.setState({loadingReceiptParentAdd: false})
  //   //   })
  //   //   .catch(error => {
  //   //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //   //     window.App.setState({loadingReceiptParentAdd: false})
  //   //   })
  // }

  useEffect(() => {
    loadLazyDataReceiptsSource();
    ReceiptgroupListService(JSON.stringify({
      filters: {
        receiptType: {
          value: 'invoice'
        },
        outgoing: {
          value: false
        },
        external: {
          value: true
        }
      }
    }))
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [lazyParamsReceiptsSource])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="receiptGroup" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptGroup"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Célszámlatömb</label>
                  <span className="p-input-icon-right">
                  {formData.receiptGroup && formData.receiptGroup.id ? (
                    <Dropdown {...input}
                              value={formData.receiptGroup.id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  ):(
                    <Dropdown {...input}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="receiptNumber" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptNumber"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Költségszámla sorszáma</label>
                  <span className="p-input-icon-right">
                    <InputText id="receiptNumber" {...input}
                               onChange={(e) => {
                                 setFormData({
                                   ...formData,
                                   receiptNumber: e.target.value
                                 })
                               }}
                               value={formData.receiptNumber}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <div className="p-field p-fluid">
                <label htmlFor="receipt">Szállítólevél csatolása</label>

                <DataTable
                  emptyMessage="Nincs találat."
                  value={receiptsSource} paginator first={lazyParamsReceiptsSource.first}
                  totalRecords={totalRecordsReceiptsSource} onPage={onPageReceiptsSource}
                  onSort={onSortReceiptsSource} sortField={lazyParamsReceiptsSource.sortField}
                  sortOrder={lazyParamsReceiptsSource.sortOrder}
                  onFilter={onFilterReceiptsSource} filters={lazyParamsReceiptsSource.filters}
                  loading={loadingReceiptsSource} lazy
                  paginatorTemplate={pagerReceiptsSource} rows={rowsReceiptsSource}
                  emptyMessage="Nincs találat."
                  autoLayout={true}
                  selection={receiptsToAdd}
                  onSelectionChange={e => {
                    if(e.value) {
                      setFormData({
                        ...formData,
                        receipt: {id: e.value.id}
                      })
                    } else {
                      setFormData({
                        ...formData,
                        receipt: null
                      })
                    }
                    setReceiptsToAdd(e.value)
                  }}
                  dataKey="id"
                  selectionMode="checkbox"
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  stripedRows
                >
                  <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
                  <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
                  {/*<Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>*/}
                  <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
                  <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (rowData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{rowData.currency}
                              </>
                            )
                          }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Tovább a költségszámlára" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ReceiptDeliverynoteInvoiceComponent);
