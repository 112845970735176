import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {TabView, TabPanel} from 'primereact/tabview';
import {Button} from "primereact/button";
import ScheduleShowService from "../../services/schedule/ScheduleShowService";
import ScheduleitemsListService from "../../services/scheduleitem/ScheduleitemsListService";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";

import ScheduleProductionitemsComponent from "./ScheduleProductionitemsComponent";
import ScheduleStartService from "../../services/schedule/ScheduleStartService";
import ScheduleDoneService from "../../services/schedule/ScheduleDoneService";
import ScheduleitemsDeleteService from "../../services/scheduleitem/ScheduleitemsDeleteService";
import ProductionitemStatusesService from "../../services/productionitem/ProductionitemStatusesService";
import ScheduleStatusesService from "../../services/schedule/ScheduleStatusesService";
import ProductionjobListService from "../../services/productionjob/ProductionjobListService";

const ScheduleShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scheduleDoneDialog, setScheduleDoneDialog] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedJobsToReschedule, setSelectedJobsToReschedule] = useState([]);

  const deleteSchedulegroup = (rowData) => {
    ScheduleitemsDeleteService(rowData.id)
      .then(response => {
        ScheduleitemsListService(window.App.state.props.id, window.App.state.valueSchedulegroupId)
          .then(response => {
            setItems(response.data);
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        window.App.toastShow('success', 'Sikeres törlés!',);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {formData.status === 'new' || formData.status === 'in_progress' &&
        <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                tooltip="Nem jelent meg"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    deleteSchedulegroup(rowData)
                  }
                }}
        />
        }
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (status) => {
    if (statuses && statuses[0]) {
      let status_ = statuses.filter(item => item.id === status)
      if (status_[0] && status_[0].name) {
        return status_[0].name
      }
    }
  }

  const [jobStatuses, setJobStatuses] = useState([]);
  const jobStatus = (rowData) => {
    if (jobStatuses && jobStatuses[0]) {
      let status_ = jobStatuses.filter(item => item.id === rowData.status)
      if (status_[0] && status_[0].name) {
        return status_[0].name
      }
    }
  }

  useEffect(() => {
    ProductionitemStatusesService()
      .then((response) => {
        setJobStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ScheduleStatusesService()
      .then((response) => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ScheduleShowService(window.App.state.valueSchedulegroupId, window.App.state.props.id)
      .then(response => {
        setFormData(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ScheduleitemsListService(window.App.state.valueSchedulegroupId, window.App.state.props.id)
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])
  return (
    <>
      <div className={"p-grid"}>

      </div>
      <hr/>
      <TabView className="tabview-header-icon menu">
        <TabPanel header="Alap adatok" leftIcon="pi pi-list">
          <Panel>
            <div className={"p-grid"}>
              {formData.dateFrom &&
              <div className={"p-col"}>
                <small className={"p-text-uppercase"}>Időponttól</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {moment.utc(formData.dateFrom).local().format('YYYY-MM-DD hh:mm')}
                </p>
              </div>
              }
              {formData.dateUntil &&
              <div className={"p-col"}>
                <small className={"p-text-uppercase"}>Időpontig</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {moment.utc(formData.dateUntil).local().format('YYYY-MM-DD hh:mm')}
                </p>
              </div>
              }
              {formData.headcount &&
              <div className={"p-col"}>
                <small className={"p-text-uppercase"}>Létszám</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {formData.headcount}
                </p>
              </div>
              }{formData.status &&
            <div className={"p-col"}>
              <small className={"p-text-uppercase"}>Státusz</small>
              <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                {status(formData.status)}
              </p>
            </div>
            }
            </div>
            {formData && formData.status === 'new' &&
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Műszak indítása" icon="pi pi-check" className="p-button-success"
                        onClick={() => {
                          ScheduleStartService(formData.id)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres indítás!');
                              ScheduleShowService(window.App.state.valueSchedulegroupId, window.App.state.props.id)
                                .then(response => {
                                  setFormData(response.data);
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })

                        }}
                />
              </div>
            </div>
            }
            {formData && formData.status === 'in_progress' &&
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Műszak befejezése" icon="pi pi-check" className="p-button-danger"
                        onClick={() => {
                          ProductionjobListService(JSON.stringify({
                            filters: {
                              status: {
                                value: ['scheduled', 'in_progress', 'paused', 'suspensed']
                              }
                            },
                            first: 0,
                            rows: 9999,
                            page: 0,
                          }))
                            .then(response => {
                              setJobs(response.data.items);
                              setScheduleDoneDialog(true)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}
                />
                <Dialog visible={scheduleDoneDialog}
                        style={{width: '980px'}}
                        header="Újra kiosztandó feladatok kiválasztása"
                        modal
                        onHide={() => {
                          setScheduleDoneDialog(false)
                        }}>
                  <div className={"p-grid"}>
                    <div className={"p-col"}>
                      <DataTable
                        value={jobs}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        stripedRows
                        selection={selectedJobs}
                        onSelectionChange={e => {
                          let jobIds = []
                          Object.entries(e.value).map(function (item, index) {
                            jobIds.push(item[1].id)
                          })
                          setSelectedJobs(e.value)
                          setSelectedJobsToReschedule(jobIds)
                        }}
                        selectionMode="checkbox"
                        dataKey="id"
                        filterDisplay="row"
                        responsiveLayout="scroll"
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="status" header="Státusz" sortable body={jobStatus}></Column>
                        <Column field="projectName" header="Projekt" sortable filter
                                filterPlaceholder="Projekt"></Column>
                        <Column field="projectItemNumber" header="Munkaszám" sortable filter
                                filterPlaceholder="Munkaszám"></Column>
                        <Column field="technologyName" header="Technológia" sortable filter
                                filterPlaceholder="Technológia"></Column>
                        <Column field="machineName" header="Gép" sortablefilter filterPlaceholder="Gép"></Column>
                        <Column field="employeeName" header="Munkavállaló" sortable filter
                                filterPlaceholder="Munkavállaló"></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Műszak befejezése" icon="pi pi-check"
                                  className="p-button-danger"
                                  onClick={() => {
                                    ScheduleDoneService(formData.id, selectedJobsToReschedule)
                                      .then(response => {
                                        window.App.toastShow('success', 'Sikeres befejezés!');
                                        ScheduleShowService(window.App.state.valueSchedulegroupId, window.App.state.props.id)
                                          .then(response => {
                                            setFormData(response.data);
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })
                                  }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
            }
          </Panel>
        </TabPanel>
        <TabPanel header="Munkavállalók" leftIcon="pi pi-list">
          <Panel>
            <DataTable value={items}
                       emptyMessage="Nincs találat."
                       autoLayout={true}
                       filterDisplay="row"
                       responsiveLayout="scroll"
                       stripedRows
                       className={"p-mb-3"}
            >
              <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="employeeName" header="Munkavállaló"></Column>
              <Column field="dateFrom" header="Időponttól" sortable body={(rowData) => {
                if (rowData.dateFrom !== null) {
                  return (
                    <span>{moment.utc(rowData.dateFrom).local().format('YYYY-MM-DD HH:mm')}</span>
                  )
                }
              }}></Column>
              <Column field="dateUntil" header="Időpontig" sortable body={(rowData) => {
                if (rowData.dateUntil !== null) {
                  return (
                    <span>{moment.utc(rowData.dateUntil).local().format('YYYY-MM-DD HH:mm')}</span>
                  )
                }
              }}></Column>
              <Column field="machineName" header="Gép"></Column>
              <Column field="hoursFree" header="Szabad órák"></Column>
              <Column field="hoursReserved" header="Foglalt órák"></Column>
            </DataTable>
          </Panel>
        </TabPanel>
        <TabPanel header="Feladatok" leftIcon="pi pi-list">
          <Panel>
            <ScheduleProductionitemsComponent scheduleId={window.App.state.props.id}/>
          </Panel>
        </TabPanel>
      </TabView>
    </>
  )
}
export default withTranslation('common')(ScheduleShowComponent);
