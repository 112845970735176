import axios from "axios";

export default function PurchaseorderStatusChangeTenderService(orderId) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/purchase_order/" + orderId + "/status_change_tender",
    data: {},
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
