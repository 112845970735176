import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {TabView, TabPanel} from 'primereact/tabview';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import {Card} from "primereact/card";
import {Timeline} from "primereact/timeline";
import download from "js-file-download";

import PurchaseorderShowService from "../../services/purchaseorder/PurchaseorderShowService";
import ReceiptListComponent from "../receipt/ReceiptListComponent";
import PurchaseorderSummaryService from "../../services/purchaseorder/PurchaseorderSummaryService";
import PurchaseorderPricingComponent from "./PurchaseorderPricingComponent";
import PurchaseorderDownloadService from "../../services/purchaseorder/PurchaseorderDownloadService";
import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import PurchaseorderStockComponent from "./PurchaseorderStockComponent";
import PurchaseorderFeerequestComponent from "./PurchaseorderFeerequestComponent";
import PurchaseorderStatusChangeTenderService
  from "../../services/purchaseorder/PurchaseorderStatusChangeTenderService";
import PurchaseorderStatusChangeOrderedService
  from "../../services/purchaseorder/PurchaseorderStatusChangeOrderedService";

const PurchaseorderShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [summary, setSummary] = useState({});

  // timeline
  const timelineMarker = (item) => {
    return (
      <span className={"custom-marker shadow-1 p-p-1 p-mt-3 p-mb-2"}>
        <i className={item.icon} style={{fontSize: '1.5em'}}></i>
      </span>
    );
  };

  const timelineConent = (item) => {
    return (
      <Card className={"p-mt-3 p-p-1"}>
        <small>{moment.utc(item.date).local().format('YYYY-MM-DD HH:mm:ss')}</small>
        <h3 className={"p-m-0 p-p-0 p-text-secondary"}>
          <span className={"text-" + item.color}>{item.status}</span>
        </h3>
        <p className={"p-mb-0"}>{item.description}</p>
      </Card>
    );
  };

  const timelineData = (items) => {
    let data = []
    items.map((item, index) => {
      data.push({
        status: item.title,
        date: item.date,
        icon: "pi pi-" + item.icon,
        description: item.description,
        color: item.theme,
      })
    })
    return data
  }

  useEffect(() => {
    if (props && props.initialValues) {
      PurchaseorderShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      PurchaseorderSummaryService(props.initialValues.id)
        .then(response => {
          setSummary(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.setState({
      lazyparamsReceiptList: {
        first: 0,
        rows: 10,
        page: 0,
        sortField: 'createDate',
        sortOrder: -1,
        filters: {
          purchaseOrder: {
            value: props.initialValues.id
          },
        }
      },
      rerenderPurchaseorderShow: false,
    })
  }, [window.App.state.rerenderPurchaseorderShow === true])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.orderNumber}</h2>
      <div className={"p-grid"}>
        {formData.createdAt &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Létrehozva
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{moment.utc(formData.createdAt).local().format('YYYY-MM-DD HH:mm')}</>
          </p>
        </div>
        }
        {formData.contactName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Kapcsolattartó
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.contactName}</>
          </p>
        </div>
        }
        {formData.status &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Státusz
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.statusName}</>
          </p>
        </div>
        }
        {formData.netAmount &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Nettó összeg
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.netAmount}</>
          </p>
        </div>
        }
        {formData.status === 'draft' ? (
          <div className={"p-col p-text-right"}>
            <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                    onClick={() => {
                      window.App.setState({
                        sidebarPurchaseorderShow: false,
                        sidebarPurchaseorderUpdate: true,
                        props: formData
                      })
                    }}
            />
          </div>
        ) : (
          <div className={"p-col p-text-right"}>
            <Button type="button" label="Módosítás" icon="pi pi-pencil" className="disabled p-button-warning"/>
          </div>
        )}
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu">
        <TabPanel header="Összefoglaló nézet" leftIcon="pi pi-chart-bar">
          <div className={"p-grid"}>
            {summary && summary.kpi && summary.kpi[0] &&
            [summary.kpi.map((kpi, index) => {
              return (
                <div className={"p-col"}>
                  <Card className={"p-p-1"}>
                    <div className={"p-grid"}>
                      <div className={"p-col-9 p-col-align-center"}>
                        <small className={"p-text-uppercase"}>{kpi.title}</small>
                        <h3 className={"p-m-0 p-p-0 p-text-secondary"}> {kpi.count}</h3>
                      </div>
                      <div className={"p-col-3 p-col-align-center p-text-right"}>
                        <i className={"pi pi-" + kpi.icon} style={{'fontSize': '1.5em'}}></i>
                      </div>
                      <div className={"p-col-12"}>
                          <span className={"text-" + kpi.subtitle_theme}>
                            <span className={"p-text-bold"}>
                              {kpi.subtitle_theme === 'success' &&
                              <i className={"pi pi-arrow-up"}></i>
                              }
                              {kpi.subtitle_theme === 'danger' &&
                              <i className={"pi pi-arrow-down"}></i>
                              }
                              {kpi.subtitle_theme === 'warning' &&
                              <i className={"pi pi-exclamation-triangle"}></i>
                              }
                              &nbsp;{kpi.subtitle}
                            </span> {kpi.description}
                          </span>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            })]
            }
          </div>
          <div className={"p-grid p-mt-2"}>
            <div className={"p-col-12 p-lg-6"}>
              <h3 className={"p-mt-0 p-mb-0 p-text-secondary"}>
                <i className={"pi pi-clock"}></i> Előzmények
              </h3>
              {summary.timeline && summary.timeline[0] ? (
                <>
                  <Timeline value={timelineData(summary.timeline)} layout="vertical" align={"left"}
                            marker={timelineMarker} content={timelineConent} className={"no-alternate-column"}/>
                </>
              ) : (
                <>Még nincs előzmény...</>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Tételek" leftIcon="pi pi-list">
          <Panel>
            <PurchaseorderFeerequestComponent orderId={props.initialValues.id} initialValues={props.initialValues}/>
          </Panel>
        </TabPanel>
        <TabPanel header="Beszállítói ajánlat" leftIcon="pi pi-dollar">
          <PurchaseorderPricingComponent initialValues={props.initialValues}/>
        </TabPanel>
        <TabPanel header="Bevételezés" leftIcon="pi pi-shopping-cart">
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3 className={"p-text-secondary"}>
                  <i className={"pi pi-list"}></i> Tételek
                </h3>
              </div>
            </div>
          }>
            <PurchaseorderStockComponent orderId={props.initialValues.id} initialValues={props.initialValues}/>
          </Panel>
        </TabPanel>
        <TabPanel header="Bizonylatok" leftIcon="pi pi-file">
          <Panel>
            <ReceiptListComponent view={"purchaseorder"}/>
          </Panel>
        </TabPanel>
      </TabView>
    </>
  )
}
export default withTranslation('common')(PurchaseorderShowComponent);
