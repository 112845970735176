import axios from "axios";

export default function PurchaseorderItemReplacementService(orderId, itemId, originalItemId) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/purchase_order/" + orderId + "/items/" + originalItemId,
    data: {
      product: {id: itemId}
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
