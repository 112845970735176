import axios from "axios";

export default function ScheduleStartService(scheduleId) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/schedule/" + scheduleId + "/start",
    data: {},
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
