import axios from "axios";

export default function ProjectSummaryService(id) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/project/" + id + "/summary",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
