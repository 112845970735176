import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch";
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";
import {AutoComplete} from "primereact/autocomplete";
import {Dialog} from "primereact/dialog";
import {FileUpload} from "primereact/fileupload";
import moment from "moment";
import {PickList} from 'primereact/picklist';
import {Tooltip} from "primereact/tooltip";
import {Message} from "primereact/message";
import {addLocale} from "primereact/api";

import EmployeeShowService from "../../services/employee/EmployeeShowService";
import EmployeeFormService from '../../services/employee/EmployeeFormService'
import IdentitydocumenttypeListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import CompanyListService from "../../services/company/CompanyListService";
import EmployeepositionListService from "../../services/employeeposition/EmployeepositionListService";
import IdentifierByEmployeeListService from "../../services/identifier/IdentifierByEmployeeListService";
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import EmployeenationalityListService from "../../services/employeenationality/EmployeenationalityListService";
import EmploymentworkorderListService from "../../services/employmentworkorder/EmploymentworkorderListService";
import EmploymenttypeListService from "../../services/employmenttype/EmploymenttypeListService";
import {InputMask} from "primereact/inputmask";

const EmployeeFormComponent = (props) => {

  const [formData, setFormData] = useState({
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    },
    active: true,
    isHomeofficeEnabled: false
  });
  const [loading, setLoading] = useState(false);
  const [identitydocumenttypes, setIdentitydocumenttypes] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [safetyTypes, setSafetyTypes] = useState([]);
  const [employmentworkorders, setEmploymentworkorders] = useState([]);
  const [employmenttypes, setEmploymenttypes] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.lastName) {
    //   errors.lastName = 'Az vezetéknév nem lehet üres';
    // }
    // if (!data.firstName) {
    //   errors.firstName = 'Az keresztnév nem lehet üres';
    // }
    // if (!data.company || !data.company.id) {
    //   errors.company = 'A cég nem lehet üres';
    // }
    // // if (!data.employeePosition) {
    // //   errors.employeePosition = 'A beosztás nem lehet üres';
    // // }
    // if (!data.birthDay) {
    //   errors.birthDay = 'A születési idő nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      EmployeeFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderEmployeeList: true})
          setFormData(response.data)
          if (props.closeForm === true) {
            window.App.setState({
              sidebarEmployeeCreate: false,
              sidebarEmployeeShow: false,
              sidebarEmployeeView: false,
              sidebarEmployeeUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      EmployeeFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          setTimeout(function () {
            IdentifierByEmployeeListService('', response.data.id)
              .then(response => {
                setIdentifiers(response.data.items)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }, 1000)
          window.App.setState({rerenderEmployeeList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarEmployeeCreate: false,
              sidebarEmployeeShow: false,
              sidebarEmployeeView: false,
              sidebarEmployeeUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // // documents
  // const [documents, setDocuments] = useState([]);
  // const [documentsLoading, setDocumentsLoading] = useState(false);
  //
  // const documentValidate = (data) => {
  //   let errors = {};
  //   if (!data.name) {
  //     errors.name = 'Kötelező mező';
  //   }
  //   // if (!data.validUntil) {
  //   //   errors.validUntil = 'Kötelező mező';
  //   // }
  //   if (documentFormMethod === 'post') {
  //     if (!data.file) {
  //       errors.file = 'Kötelező mező';
  //     }
  //   }
  //   return errors;
  // };
  //
  // const documentActionBody = (rowData, row) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
  //               tooltip="Módosítás"
  //               onClick={() => {
  //                 setDocumentDialog(true)
  //                 setDocumentFormData({...rowData, rowIndex: row.rowIndex})
  //                 setDocumentFormMethod('put')
  //                 window.App.setState({props: rowData.id})
  //               }}
  //       />
  //       {/*<Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
  //       {/*        tooltip="Törlés"*/}
  //       {/*        onClick={() => {*/}
  //       {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
  //       {/*            documentDelete(rowData, row)*/}
  //       {/*          }*/}
  //       {/*        }}*/}
  //       {/*/>*/}
  //     </React.Fragment>
  //   );
  // }
  // const [documentDialog, setDocumentDialog] = useState(false);
  // const [documentFormData, setDocumentFormData] = useState({}); // set on dialog open!
  // const [documentFormMethod, setDocumentFormMethod] = useState('post');
  //
  // const documentOnSubmit = (data, form) => {
  //   setDocumentsLoading(true)
  //   EmployeedocumentFormService(data, documentFormMethod, formData.id, documentFormData.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres mentés!');
  //       setDocumentDialog(false)
  //       setDocumentsLoading(false)
  //       setDocumentFormData({})
  //       EmployeedocumentListService('', formData.id)
  //         .then(response => {
  //           setTimeout(function () {
  //             setDocuments(response.data.items)
  //           }, 1000)
  //         })
  //         .catch(error => {
  //           window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //         })
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //       setDocumentsLoading(false)
  //     })
  // }
  //
  // const documentDelete = (data, row) => {
  //
  // }
  //
  //
  // const [documentImgDialog, setDocumentImgDialog] = useState(false);
  //
  // // image for document
  // const documentUpload = (files) => {
  //   var reader = new FileReader();
  //   let name = files.files[0].name
  //   reader.onloadend = () => {
  //     var base64data = reader.result;
  //     setDocumentFormData({
  //       ...documentFormData,
  //       file: base64data,
  //       originalFileName: name
  //     })
  //   }
  //   let image = reader.readAsDataURL(files.files[0]);
  // }
  //
  // // safeties
  // const [safeties, setSafeties] = useState([]);
  // const [safetiesLoading, setSafetiesLoading] = useState(false);
  //
  // const safetyValidate = (data) => {
  //   let errors = {};
  //   // if (!data.name) {
  //   //   errors.name = 'Kötelező mező';
  //   // }
  //   // if (!data.validUntil) {
  //   //   errors.validUntil = 'Kötelező mező';
  //   // }
  //   if (!data.occupationalSafety) {
  //     errors.occupationalSafety = 'Kötelező mező';
  //   }
  //   return errors;
  // };
  //
  // const safetyActionBody = (rowData, row) => {
  //   return (
  //     <React.Fragment>
  //       <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
  //               onClick={() => {
  //                 setSafetyEditDialog(true)
  //                 setSafetyFormData({...rowData, rowIndex: row.rowIndex})
  //               }}
  //       />
  //       <Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"
  //               onClick={() => {
  //                 if (window.confirm('A törlés megerősítésére van szükség!')) {
  //                   safetyDelete(rowData, row)
  //                 }
  //               }}
  //       />
  //     </React.Fragment>
  //   );
  // }
  // const [safetyDialog, setSafetyDialog] = useState(false);
  // const [safetyEditDialog, setSafetyEditDialog] = useState(false);
  // const [safetyFormData, setSafetyFormData] = useState({}); // set on dialog open!
  //
  // const safetyOnSubmit = (data, form) => {
  //   setSafetiesLoading(true)
  //   let _data = {}
  //   _data = {
  //     ...data,
  //     employee: {id: formData.id}
  //   }
  //   SafetyemployeeFormService(_data, 'post', formData.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres mentés!');
  //       SafetyemployeeByemployeeListService(formData.id)
  //         .then(response => {
  //           setSafeties(response.data.items)
  //         })
  //         .catch(error => {
  //           window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //         })
  //       setSafetyFormData({})
  //       setSafetyDialog(false)
  //       setSafetiesLoading(false)
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //       setSafetiesLoading(false)
  //     })
  //
  // }
  // const safetyEditOnSubmit = (data, form) => {
  //   setSafetiesLoading(true)
  //   let _data = {}
  //   _data = {
  //     ...data,
  //     employee: {id: formData.id}
  //   }
  //   SafetyemployeeFormService(_data, 'put', data.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres mentés!');
  //       SafetyemployeeByemployeeListService(formData.id)
  //         .then(response => {
  //           setSafeties(response.data.items)
  //         })
  //         .catch(error => {
  //           window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //         })
  //       setSafetyFormData({})
  //       setSafetyEditDialog(false)
  //       setSafetiesLoading(false)
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //       setSafetiesLoading(false)
  //     })
  //
  // }
  //
  // const safetyDelete = (data, row) => {
  //   SafetyemployeeDeleteService(data.id)
  //     .then(response => {
  //       SafetyemployeeByemployeeListService(props.initialValues.id)
  //         .then(response => {
  //           setSafeties(response.data.items)
  //         })
  //         .catch(error => {
  //           window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //         })
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //     })
  // }
  //
  // const safetyType = (id) => {
  //   if (safetyTypes && safetyTypes[0]) {
  //     let safetyType = safetyTypes.filter(item => item.id === Number(id))
  //     if(safetyType && safetyType[0] && safetyType[0].value) {
  //       return safetyType[0].value
  //     }
  //   }
  // }
  //
  // const [occSafeties, setOccSafeties] = useState({});

  // image
  const imageUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      var base64data = reader.result;
      setFormData({
        ...formData,
        profileImageFile: base64data
      })
    }
    let file = reader.readAsDataURL(files.files[0]);
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // positons autocomplete
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('');

  const searchPosition = (event) => {
    EmployeepositionListService(JSON.stringify({
      filters: {name: {value: event.query}},
      "sortField": "name",
      "sortOrder": -1,
      first: 0,
      rows: 200,
      page: 0,
    }))
      .then(response => {
        setPositions(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // nationality autocomplete
  const [nationalities, setNationalities] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState('');

  const searchNationality = (event) => {
    EmployeenationalityListService(JSON.stringify({
      filters: {name: {value: event.query}},
      "first": 0,
      "rows": 100,
      "page": 0,
      "sortField": "name",
      "sortOrder": -1
    }))
      .then(response => {
        setNationalities(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    IdentitydocumenttypeListService()
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    // SafetyWithoutEducationListService()
    //   .then(response => {
    //     let _occSafeties = []
    //     Object.entries(response.data.items).map(function (safety, index) {
    //       _occSafeties.push({
    //         name: safety[1].name + " - " + safety[1].workTypeName + " - " + safety[1].entryLocationName,
    //         id: safety[1].id
    //       })
    //     })
    //     setOccSafeties(_occSafeties)
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
    SafetyTypeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setSafetyTypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmployeenationalityListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setNationalities(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmploymentworkorderListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        response.data.items.map(function(item, index){
          if(item.isDefault === true) {
            setFormData({
              ...formData,
              employmentWorkOrder: {id: item.id}
            })
          }
        })
        setEmploymentworkorders(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmploymenttypeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setEmploymenttypes(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (formData.id) {
      IdentifierByEmployeeListService('', formData.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // EmployeedocumentListService('', formData.id)
      //   .then(response => {
      //     setDocuments(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
    }
    if (props && props.initialValues) {
      setSelectedCompany(props.initialValues.companyName)
      setSelectedPosition(props.initialValues.employeePositionName)
      IdentifierByEmployeeListService('', props.initialValues.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // EmployeedocumentListService('', props.initialValues.id)
      //   .then(response => {
      //     setDocuments(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
      EmployeeShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if (response.data.employeeNationalityName) {
            setSelectedNationality(response.data.employeeNationalityName)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // SafetyemployeeByemployeeListService(props.initialValues.id)
      //   .then(response => {
      //     setSafeties(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
    }
  }, [])

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={formData}
            validate={validate} render={({handleSubmit}) => (
        <>
          <form onSubmit={handleSubmit} className="">
            <Panel className={""}>
              <div className={"p-grid p-mb-5"}>
                <div className={"p-col-12 p-p-0"}>
                  {process.env.REACT_APP_BRAND === 'entryfish' &&
                  <Message severity="warn" text="
                    Figyelem! Minden rögzített adatot csatolt dokumentummal kell igazolni. A rögzített adato(ka)t és
                    dokumentumo(ka)t a projektgazda bármikor ellenőrizheti. Visszaélés esetén szankció kiszabására jogosult.
                    "></Message>
                  }
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-8 p-xl-8"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Alapadatok
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-md-5 p-lg-5"}>
                      <Field name="lastName" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="lastName"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Vezetéknév</label>
                          <span className="p-input-icon-right">
                        <InputText id="lastName" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       lastName: e.target.value
                                     })
                                   }}
                                   value={formData.lastName}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-5 p-lg-5"}>
                      <Field name="firstName" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="firstName"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Keresztnév</label>
                          <span className="p-input-icon-right">
                        <InputText id="firstName" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       firstName: e.target.value
                                     })
                                   }}
                                   value={formData.firstName}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-lg-2 p-xl-2"}>
                      <Field name="active" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="active"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                          <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <Field name="email" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="email"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                          <span className="p-input-icon-right">
                        <InputText id="email" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       email: e.target.value
                                     })
                                   }}
                                   value={formData.email}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <Field name="phone" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="phone"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Telefonszám</label>
                          <span className="p-input-icon-right">
                        <InputText id="email" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       phone: e.target.value
                                     })
                                   }}
                                   value={formData.phone}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                  </div>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-sitemap"}></i> Foglalkoztatás
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6"}>
                      <Field name="company" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="company"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Vállalkozás</label>
                          <span className="p-input-icon-right">
                            {localStorage.getItem('roles').toString().includes('role_company_browse')
                            || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                              <AutoComplete value={selectedCompany}
                                            id={"company"}
                                            suggestions={companies}
                                            forceSelection
                                            completeMethod={searchCompany}
                                            field={"name"}
                                            delay="1000"
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                company: {id: e.value.id}
                                              })
                                              localStorage.setItem('defaultCompanyId', e.value.id)
                                              localStorage.setItem('defaultCompanyName', e.value.name)
                                            }}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                company: {id: null}
                                              })
                                              setSelectedCompany(e.value)
                                            }}
                                            dropdown
                                            onClear={(e) => {
                                              setSelectedCompany('')
                                            }}
                              />
                            ) : (
                              <Dropdown disabled className={"disabled"} id={"company"}/>
                            )}
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-lg-6"}>
                      <Field name="employeePosition" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="employeePosition"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Beosztás</label>
                          <span className="p-input-icon-right">
                            <AutoComplete value={selectedPosition}
                                          id={"employeePosition"}
                                          suggestions={positions}
                                          forceSelection
                                          completeMethod={searchPosition}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              employeePosition: {id: e.value.id}
                                            })
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              employeePosition: {id: null}
                                            })
                                            setSelectedPosition(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedPosition('')
                                          }}
                            />
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6"}>
                      <Field name="employmentWorkOrder" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="employmentWorkOrder"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Munkarend</label>
                          <span className="p-input-icon-right">
                            {localStorage.getItem('roles').toString().includes('role_employment_work_order_read') ? (
                              <>
                                {formData && formData.employmentWorkOrder && formData.employmentWorkOrder.id ? (
                                  <Dropdown options={employmentworkorders}
                                            optionLabel="name"
                                            optionValue="id"
                                            id={"employmentWorkOrder"}
                                            emptyMessage={"Még nincs munkarend..."}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                employmentWorkOrder: {id: e.value}
                                              })
                                            }}
                                            value={Number(formData.employmentWorkOrder.id)}
                                  />
                                ) : (
                                  <Dropdown options={employmentworkorders}
                                            optionLabel="name"
                                            optionValue="id"
                                            id={"employmentWorkOrder"}
                                            emptyMessage={"Még nincs munkarend..."}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                employmentWorkOrder: {id: e.value}
                                              })
                                            }}
                                  />
                                )}
                              </>
                            ) : (
                              <Dropdown disabled className={"disabled"} id={"employmentWorkOrder"}/>
                            )}
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-lg-6"}>
                      <Field name="employmentType" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="employmentType"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Foglalkoztatás
                            formája</label>
                          <span className="p-input-icon-right">
                            {localStorage.getItem('roles').toString().includes('role_employment_type_read') ? (
                              <>
                                {formData && formData.employmentType && formData.employmentType.id ? (
                                  <Dropdown options={employmenttypes}
                                            optionLabel="name"
                                            optionValue="id"
                                            id={"employmentWorkOrder"}
                                            emptyMessage={"Még nincs foglalkoztatás forma..."}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                employmentType: {id: e.value}
                                              })
                                            }}
                                            value={Number(formData.employmentType.id)}
                                  />
                                ) : (
                                  <Dropdown options={employmenttypes}
                                            optionLabel="name"
                                            optionValue="id"
                                            id={"employmentWorkOrder"}
                                            emptyMessage={"Még nincs foglalkoztatás forma..."}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                employmentType: {id: e.value}
                                              })
                                            }}
                                  />
                                )}
                              </>
                            ) : (
                              <Dropdown disabled className={"disabled"} id={"employmentType"}/>
                            )}
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    {process.env.REACT_APP_EMPLOYEE_HOMEOFFICE_ENABLED === 'true' &&
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <Field name="isHomeofficeEnabled" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="isHomeofficeEnabled"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Otthoni munkavégzés
                            engedélyezve</label>
                          <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.isHomeofficeEnabled}
                      value={formData.isHomeofficeEnabled}
                      onChange={e => setFormData({...formData, isHomeofficeEnabled: e.target.value})}/>
                  </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    }
                  </div>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-user"}></i> Személyes adatok
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="birthPlace" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="birthPlace"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Születési hely</label>
                          <span className="p-input-icon-right">
                        <InputText id="birthPlace" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       birthPlace: e.target.value
                                     })
                                   }}
                                   value={formData.birthPlace}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="birthDay" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="birthDay"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Születés ideje</label>
                          <span className="p-input-icon-right">
                                <InputMask id={"birthDay"}
                                           mask={"9999-99-99"}
                                           placeholder={"ÉÉÉÉ-HH-NN"}
                                           value={formData.birthDay}
                                           onChange={(e) => {
                                             if (e.value.indexOf("_") === -1) {
                                               if (e.value === '') {
                                                 e.value = null
                                               }
                                               setFormData({
                                                 ...formData,
                                                 birthDay: e.value
                                               })
                                             }
                                           }}
                                />
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="employeeNationality" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="employeeNationality"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Állampolgárság</label>
                          <span className="p-input-icon-right">
                            <AutoComplete value={selectedNationality}
                                          id={"nationality"}
                                          suggestions={nationalities}
                                          completeMethod={searchNationality}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              employeeNationality: {id: e.value.id}
                                            })
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              employeeNationality: {id: null}
                                            })
                                            setSelectedNationality(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedNationality('')
                                          }}
                            />
                  </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="identityDocumentType" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="identityDocumentType"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Okmány típusa</label>
                          <span className="p-input-icon-right">
                            <Dropdown options={identitydocumenttypes}
                                      optionLabel="name"
                                      optionValue="id"
                                      id={"identityDocumentType"}
                                      emptyMessage={"Még nincs dokumentum..."}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          identityDocumentType: e.value
                                        })
                                      }}
                                      value={Number(formData.identityDocumentType)}
                            />
                  </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="identityDocumentNumber" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="identityDocumentNumber"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Okmányazonosító</label>
                          <span className="p-input-icon-right">
                        <InputText id="identityDocumentNumber" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       identityDocumentNumber: e.target.value
                                     })
                                   }}
                                   value={formData.identityDocumentNumber}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-4 p-lg-4"}>
                      <Field name="identityDocumentValidUntil" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="identityDocumentValidUntil"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Okmány lejárat</label>
                          <span className="p-input-icon-right">
                                <InputMask id={"identityDocumentValidUntil"}
                                           mask={"9999-99-99"}
                                           placeholder={"ÉÉÉÉ-HH-NN"}
                                           value={formData.identityDocumentValidUntil}
                                           onChange={(e) => {
                                             if (e.value.indexOf("_") === -1) {
                                               if (e.value === '') {
                                                 e.value = null
                                               }
                                               setFormData({
                                                 ...formData,
                                                 identityDocumentValidUntil: e.value
                                               })
                                             }
                                           }}
                                />
                          </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-6 p-lg-6"}>
                      <Field name="taxNumber" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="taxNumber"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Adóazonosító</label>
                          <span className="p-input-icon-right">
                        <InputText id="taxNumber" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       taxNumber: e.target.value
                                     })
                                   }}
                                   value={formData.taxNumber}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col-12 p-md-6 p-lg-6"}>
                      <Field name="insuranceNumber" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="insuranceNumber"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>TAJ szám</label>
                          <span className="p-input-icon-right">
                        <InputText id="insuranceNumber" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       insuranceNumber: e.target.value
                                     })
                                   }}
                                   value={formData.insuranceNumber}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                  </div>
                </div>
                <div className={"p-col-12 p-lg-4 p-xl-4 panel-highlight p-px-3"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-image"}></i> Fotó
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <div className="p-field p-fluid p-mb-3">
                        {formData.profileImageFile &&
                        <>
                          <label htmlFor="file">Feltöltendő kép</label>
                          <img src={formData.profileImageFile} className={"w-100 p-d-block"}/>
                        </>
                        }
                        {formData.id && !formData.profileImageFile &&
                        <>
                          <label htmlFor="file">Feltöltött kép</label>
                          {formData.id &&
                          <img src={process.env.REACT_APP_API_HOST + "/employee/profileImage/" + formData.id
                          + "?token=" + localStorage.getItem("userToken") + "?date=" + new Date()}
                               className={"w-100 p-d-block"}/>
                          }
                        </>
                        }
                      </div>
                      <div className="p-field p-fluid">
                        <label htmlFor="file">Kép feltöltése</label>
                        <span className="p-d-block">
                            <FileUpload mode="basic"
                                        name="file"
                                        id={"file"}
                                        auto="true"
                                        maxFileSize={1000000}
                                        customUpload
                                        uploadHandler={imageUpload}
                                        chooseLabel={"Fájl kiválasztása"}
                                        uploadLabel={"Feltöltés"}
                                        className={""}
                            />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  {formData.id &&
                  <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                          onClick={() => {
                            window.App.setState({
                              sidebarEmployeeView: true,
                              sidebarEmployeeCreate: false,
                              props: formData,
                              modeEmployeeView: 'show',
                            })
                          }}
                          className="p-button"/>
                  }
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success"/>
                  <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
            </Panel>
          </form>
        </>
      )}/>
    </>
  )
}
export default withTranslation('common')(EmployeeFormComponent);
