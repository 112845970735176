import axios from "axios";

export default function SafetyemployeeFormService(formData, method, employeeId) {
  let url
  if(method === 'post') {
    url = process.env.REACT_APP_API_HOST + "/safety/employee"
  }
  else {
    url = process.env.REACT_APP_API_HOST + "/safety/employee/" + employeeId
  }
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
