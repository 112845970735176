import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import currencyFormatter from "currency-formatter";


import ReceiptParentAddService from "../../services/receipt/ReceiptParentAddService";
import ReceiptParentListService from "../../services/receipt/ReceiptParentListService";
import ReceiptParentRemoveService from "../../services/receipt/ReceiptParentRemoveService";
import ReceiptListService from "../../services/receipt/ReceiptListService";

const ReceiptParentsComponent = (props) => {

  // receipts assign
  const [receiptsSource, setReceiptsSource] = useState([]);
  const [receiptsToAdd, setReceiptsToAdd] = useState([]);
  const [receiptsTarget, setReceiptsTarget] = useState([]);
  const [receiptsToDelete, setReceiptsToDelete] = useState([]);

  const [loadingReceiptsSource, setLoadingReceiptsSource] = useState(false);
  const [totalRecordsReceiptsSource, setTotalRecordsReceiptsSource] = useState(0);
  const [rowsReceiptsSource, setRowsReceiptsSource] = useState(10);
  const [lazyParamsReceiptsSource, setLazyParamsReceiptsSource] = useState({
    first: 0,
    rows: rowsReceiptsSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutReceiptsSource = null;
  const loadLazyDataReceiptsSource = (worktypeId) => {
    setLoadingReceiptsSource(true);
    if (loadLazyTimeoutReceiptsSource) {
      clearTimeout(loadLazyTimeoutReceiptsSource);
    }
    loadLazyTimeoutReceiptsSource = setTimeout(() => {
      ReceiptListService(JSON.stringify(lazyParamsReceiptsSource))
        .then(response => {
          setTotalRecordsReceiptsSource(response.data.total_item_count);
          setReceiptsSource(response.data.items);
          setLoadingReceiptsSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const onSortReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const onFilterReceiptsSource = (event) => {
    let _lazyParams = {...lazyParamsReceiptsSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsReceiptsSource(_lazyParams);
  }
  const pagerReceiptsSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsReceiptsSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const onReceiptsSubmit = () => {
    window.App.setState({loadingReceiptParentAdd: true})
    let _receiptsToAdd = []
    Object.entries(receiptsToAdd).map(function (employee, index) {
      _receiptsToAdd.push(employee[1].id)
    })
    ReceiptParentAddService(_receiptsToAdd, props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataReceiptsSource(props.initialValues.id);
        loadLazyDataReceiptsTarget(props.initialValues.id);
        window.App.setState({loadingReceiptParentAdd: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingReceiptParentAdd: false})
      })
  }

  const [loadingReceiptsTarget, setLoadingReceiptsTarget] = useState(false);
  const [totalRecordsReceiptsTarget, setTotalRecordsReceiptsTarget] = useState(0);
  const [rowsReceiptsTarget, setRowsReceiptsTarget] = useState(10);
  const [lazyParamsReceiptsTarget, setLazyParamsReceiptsTarget] = useState({
    first: 0,
    rows: rowsReceiptsTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutReceiptsTarget = null;
  const loadLazyDataReceiptsTarget = (worktypeId) => {
    setLoadingReceiptsTarget(true);
    if (loadLazyTimeoutReceiptsTarget) {
      clearTimeout(loadLazyTimeoutReceiptsTarget);
    }
    loadLazyTimeoutReceiptsTarget = setTimeout(() => {
      ReceiptParentListService(JSON.stringify(lazyParamsReceiptsTarget), worktypeId)
        .then(response => {
          setTotalRecordsReceiptsTarget(response.data.total_item_count);
          setReceiptsTarget(response.data.items);
          setLoadingReceiptsTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageReceiptsTarget = (event) => {
    let _lazyParams = {...lazyParamsReceiptsTarget, ...event};
    setLazyParamsReceiptsTarget(_lazyParams);
  }
  const onSortReceiptsTarget = (event) => {
    let _lazyParams = {...lazyParamsReceiptsTarget, ...event};
    setLazyParamsReceiptsTarget(_lazyParams);
  }
  const onFilterReceiptsTarget = (event) => {
    let _lazyParams = {...lazyParamsReceiptsTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsReceiptsTarget(_lazyParams);
  }
  const pagerReceiptsTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsReceiptsTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const onReceiptsDelete = () => {
    window.App.setState({loadingReceiptParentRemove: true})
    let _receiptsToDelete = []
    Object.entries(receiptsToDelete).map(function (employee, index) {
      _receiptsToDelete.push(employee[1].id)
    })
    ReceiptParentRemoveService(_receiptsToDelete, props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataReceiptsSource(props.initialValues.id);
        loadLazyDataReceiptsTarget(props.initialValues.id);
        window.App.setState({loadingReceiptParentRemove: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingReceiptParentRemove: false})
      })
  }

  useEffect(() => {
    if (props && props.initialValues) {
      loadLazyDataReceiptsSource(props.initialValues.id);
      loadLazyDataReceiptsTarget(props.initialValues.id);
    }
  }, [lazyParamsReceiptsSource, lazyParamsReceiptsTarget])

  return (
    <>
      {localStorage.getItem('roles').toString().includes('role_') ? (
        <>
          <Panel className={"w-100"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-5 p-xl-5"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Bizonylatok
                    </h3>
                  </div>
                </div>

                <DataTable
                  emptyMessage="Nincs találat."
                  value={receiptsSource} paginator first={lazyParamsReceiptsSource.first}
                  totalRecords={totalRecordsReceiptsSource} onPage={onPageReceiptsSource}
                  onSort={onSortReceiptsSource} sortField={lazyParamsReceiptsSource.sortField}
                  sortOrder={lazyParamsReceiptsSource.sortOrder}
                  onFilter={onFilterReceiptsSource} filters={lazyParamsReceiptsSource.filters}
                  loading={loadingReceiptsSource} lazy
                  paginatorTemplate={pagerReceiptsSource} rows={rowsReceiptsSource}
                  emptyMessage="Nincs találat."
                  autoLayout={true}
                  selection={receiptsToAdd} onSelectionChange={e => {
                  setReceiptsToAdd(e.value)
                }} dataKey="id"
                  selectionMode="checkbox"
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  stripedRows
                >
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
                  {/*<Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>*/}
                  <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
                  <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (rowData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{rowData.currency}
                              </>
                            )
                          }}></Column>
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>

                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    <Button type="button" label="Kijelöltek hozzáadása" icon={"pi pi-plus"}
                            loading={window.App.state.loadingReceiptParentAdd}
                            onClick={onReceiptsSubmit}
                            className="p-button-success"/>
                  </div>
                </div>
              </div>
              <div className={"p-col-12 p-lg-7 p-xl-7"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Kapcsolódó bizonylatok
                    </h3>
                  </div>
                </div>
                <DataTable
                  emptyMessage="Nincs találat."
                  value={receiptsTarget} paginator first={lazyParamsReceiptsTarget.first}
                  totalRecords={totalRecordsReceiptsTarget} onPage={onPageReceiptsTarget}
                  onSort={onSortReceiptsTarget} sortField={lazyParamsReceiptsTarget.sortField}
                  sortOrder={lazyParamsReceiptsTarget.sortOrder}
                  onFilter={onFilterReceiptsTarget} filters={lazyParamsReceiptsTarget.filters}
                  loading={loadingReceiptsTarget} lazy
                  paginatorTemplate={pagerReceiptsTarget} rows={rowsReceiptsTarget}
                  emptyMessage="Nincs találat."
                  autoLayout={true}
                  selection={receiptsToDelete} onSelectionChange={e => {
                  setReceiptsToDelete(e.value)
                }} dataKey="id"
                  selectionMode="checkbox"
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  stripedRows
                >
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  {/*<Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>*/}
                  {/*<Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>*/}
                  <Column field="parentReceiptNumber" header="Előzmény bizonylat" sortable filter filterPlaceholder="Előzmény bizonylat"></Column>
                  <Column field="childReceiptNumber" header="Származtatott bizonylat" sortable filter filterPlaceholder="Származtatott bizonylat"></Column>
                  <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
                  <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (rowData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{rowData.currency}
                              </>
                            )
                          }}></Column>
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>

                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    <Button type="button" label="Kijelöltek eltávolítása"
                            onClick={onReceiptsDelete}
                            icon="pi pi-trash"
                            loading={window.App.state.loadingReceiptParentRemove}
                            className="p-button-danger"/>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </>
      ) : (
        <p>Nincs jogosultság.</p>
      )}
    </>
  )
}
export default withTranslation('common')(ReceiptParentsComponent);
