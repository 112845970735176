import axios from "axios";

export default function CompanyShowService(companyId) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/company/" + companyId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
