import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import currencyFormatter from "currency-formatter";

import OfferitemListService from "../../services/offer/OfferitemListService";
import {Dialog} from "primereact/dialog";
import OfferitemFormComponent from "./OfferitemFormComponent";
import OfferitemDeleteService from "../../services/offer/OfferitemDeleteService";
import OfferitemProductListComponent from "./OfferitemProductListComponent";
import OfferitemPricelistitemListComponent from "./OfferitemPricelistitemListComponent";

const OfferitemListComponent = (props) => {

  const [offeritem, setOfferitem] = useState([]);
  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderOfferitemList: false})
      OfferitemListService(props.initialValues.id, JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setOfferitem(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const deleteOfferitem = (rowData) => {
    OfferitemDeleteService(props.initialValues.id, rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {props && props.initialValues && props.initialValues.status === 'DRAFT' &&
        <>
          {/*{localStorage.getItem('roles').toString().includes('role_offeritem_write') &&*/}
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      dialogOfferitemUpdate: true,
                      offerItemRowData: rowData
                    })
                  }}/>
          {/*}*/}
          {/*{localStorage.getItem('roles').toString().includes('role_offeritem_write') &&*/}
          <Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"
                  tooltip="Törlés"
                  onClick={() => {
                    if (window.confirm('A törlés megerősítésére van szükség!')) {
                      deleteOfferitem(rowData)
                    }
                  }}
          />
          {/*}*/}
        </>
        }
      </React.Fragment>
    );
  }

  const [expandedRows, setExpandedRows] = useState(null);
  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <h3 className={"p-mt-0 p-pt-0"}>Szolgáltatások</h3>
          <OfferitemPricelistitemListComponent itemId={data.id}/>
          <h3 className={"p-mt-0 p-pt-0"}>Termékek vagy alapanyagok</h3>
          <OfferitemProductListComponent itemId={data.id}/>
        </div>
      </>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderOfferitemList === true])

  return (

    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={offeritem} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        stripedRows
        selectionMode="checkbox"
        dataKey="id"
        filterDisplay="row"
        responsiveLayout="scroll"
        dataKey="id"
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowExpand={(e) => {
          let id = e.data.id
          setExpandedRows({[id]: true})
          window.App.setState({
            offerItemRowData: e.data
          })
        }}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
        <Column field="externalReference" header="Külső hivatkozás" sortable filter
                filterPlaceholder="Külső hivatkozás"></Column>
        <Column field="netAmount" header="Nettó érték" className={"p-text-right p-text-nowrap"}
                body={(rowData) => {
                  let currency
                  if (formData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.netAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )
                      }
                      &nbsp;{formData.currency}
                    </>
                  )
                }}></Column>
        <Column field="grossAmount" header="Bruttó érték" className={"p-text-right p-text-nowrap"}
                body={(rowData) => {
                  let currency
                  if (formData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.grossAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )
                      }
                      &nbsp;{formData.currency}
                    </>
                  )
                }}></Column>
        <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
          return (
            <>
              {rowData.quantity} {rowData.unitName}
            </>
          )
        }}></Column>
        <Column expander style={{width: '3em'}}/>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-text-right"}>
          {props && props.initialValues && props.initialValues.status === 'DRAFT' ? (
            <>
              <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus" className="p-button-success p-ml-2"
                      onClick={() => {
                        window.App.setState({
                          dialogOfferitemCreate: true,
                        })
                      }}
              />
            </>
            ):(
              <>
                <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus"
                        disabled className="p-button-success p-ml-2"
                />
              </>
            )}
          <Dialog visible={window.App.state.dialogOfferitemUpdate}
                  style={{width: '750px'}}
                  header="Tétel módosítása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogOfferitemUpdate: false
                    })
                  }}>
            <OfferitemFormComponent offerId={window.App.state.props.id}
                                    initialValues={window.App.state.offerItemRowData}/>
          </Dialog>
          <Dialog visible={window.App.state.dialogOfferitemCreate}
                  style={{width: '750px'}}
                  header="Tétel hozzáadása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogOfferitemCreate: false
                    })
                  }}>
            <OfferitemFormComponent offerId={window.App.state.props.id}/>
          </Dialog>
        </div>
      </div>
    </>

  )
}
export default withTranslation('common')(OfferitemListComponent);
