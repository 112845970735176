import axios from 'axios'

export default function EmployeeImportService(formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/employee/import",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
}
