import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import UserPasswordReminderService from "../services/user/UserPasswordReminderService";

const PasswordResetPage = () => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.identifier) {
    //   errors.identifier = 'Az email mező nem lehet üres.';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.identifier)) {
    //   errors.identifier = 'Mindenképpen email formátumra van szükség! Pl. example@email.com';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    UserPasswordReminderService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres jelszó kérés!', 'Emailben elküldtük a további tennivalókat.');
        window.location.replace('/#/login');
        window.App.forceUpdate()
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.title = 'Elfelejtett jelszó kérése' + ' - ' + process.env.REACT_APP_TITLE;
    document.getElementById('body').classList.add('outside')
  });

  return (
    <div className="LoginPage p-component p-p-3">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE).default} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12"}>
            <h3>
              <i className={"pi pi-user"}></i> Elfelejtett jelszó kérése
            </h3>
          </div>
        </div>
      } className={""}>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={{identifier: '', password: ''}}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="identifier" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="identifier"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-envelope"/>
                    <InputText id="identifier" {...input}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/login"}>Bejelentkezés</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  {process.env.REACT_APP_REGISTRATION_LANDING_URL ? (
                    <a href={process.env.REACT_APP_REGISTRATION_LANDING_URL}>Regisztráció</a>
                  ) : (
                    <Link to={"/registration"}>Regisztráció</Link>
                  )}
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Jelszó kérése" className="p-button-success" icon={"pi pi-check"} loading={loading} disabled={submitting}/>
              </div>
            </div>
            <div className={"p-text-center p-mt-5"}>
              <span className={"p-text-secondary"} dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear()) }}></span>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(PasswordResetPage);
