import axios from "axios";

export default function EmployeeListService(query) {
  if (query.includes('"multiSortMeta":[],', '')) {
    query = query.replace('"multiSortMeta":[],', '')
  }
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/employee?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
