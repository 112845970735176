import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import currencyFormatter from "currency-formatter";
import {InputSwitch} from "primereact/inputswitch";

import OfferitemFormService from '../../services/offer/OfferitemFormService'
import OfferitemShowService from "../../services/offer/OfferitemShowService";
import UnitListService from "../../services/unit/UnitListService";
import VatListService from "../../services/vat/VatListService";

const OfferitemFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    unit: {id: Number(localStorage.getItem("defaultUnitId"))},
    vat: Number(localStorage.getItem("defaultVatId")),
    isUnitPriceGr: JSON.parse(localStorage.getItem("defaultIsUnitPriceBr")),
  });
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [vats, setVats] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      OfferitemFormService(props.offerId, data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderOfferitemList: true,
            dialogOfferitemUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      OfferitemFormService(props.offerId, data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderOfferitemList: true,
            dialogOfferitemCreate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    VatListService()
      .then(response => {
        setVats(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      OfferitemShowService(window.App.state.props.id, window.App.state.offerItemRowData.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="externalReference" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="externalReference"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Külső hivatkozás</label>
                  <span className="p-input-icon-right">
                      <InputText id="externalReference" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     externalReference: e.target.value
                                   })
                                 }}
                                 value={formData.externalReference}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <Field name="description" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="description"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Részletes leírás</label>
                  <span className="p-input-icon-right">
                      <InputText id="description" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     description: e.target.value
                                   })
                                 }}
                                 value={formData.description}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                  <span className="p-input-icon-right">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="unitPriceOriginal" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unitPriceOriginal"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egységár</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="unitPriceOriginal" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               unitPriceOriginal: e.value
                                             })
                                           }}
                                           value={formData.unitPriceOriginal}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="vat" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="vat"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>ÁFA</label>
                  <span className="p-input-icon-right">
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              vat: e.target.value
                                            })
                                            localStorage.setItem("defaultVatId", e.target.value)
                                          }}
                                          value={formData.vat}
                                          options={vats}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"vat"}
                                          emptyMessage={"Még nincs ÁFA..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="isUnitPriceGr" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="itemFormData.isUnitPriceGr"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Bruttó</label>
                  <span className="p-d-block">
                              <InputSwitch {...input}
                                           value={formData.isUnitPriceGr}
                                           checked={formData.isUnitPriceGr}
                                           onChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               isUnitPriceGr: e.value
                                             })
                                             localStorage.setItem("defaultIsUnitPriceBr", e.value)
                                           }}
                                           className={"p-mt-1"}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col"}>
            </div>
            <div className={"p-col p-text-right"}>
              <table align="right" style={{background: "none !important"}}>
                <tr style={{background: "none !important"}}>
                  <td className={"p-text-nowrap p-text-right"} style={{background: "none !important"}}>
                    {formData && formData.netAmount && formData.quantity &&
                    <>
                      Összesen:
                    </>
                    }
                  </td>
                  <td style={{background: "none !important"}}>
                    {formData && formData.netAmount && formData.quantity &&
                    <>
                      {(() => {
                        let currency = 'HUF'
                        if (currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              formData.netAmount * formData.quantity, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}
                          </>
                        )
                      })()}
                    </>
                    }
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(OfferitemFormComponent);
