import axios from "axios";

export default function CompanyLogoUploadService(companyId, file) {
  return axios({
    method: "put",
    url: process.env.REACT_APP_API_HOST + "/company/logo/" + companyId,
    data: {
      logo: file
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
