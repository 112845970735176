import axios from "axios";

export default function UserRegistrationFinishService(formData, activationHash) {

  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/registration/" + activationHash,
    data: formData,
    params: {},
    headers: {}
  });
}
