import axios from "axios";

export default function UserPasswordReminderService(formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/password_reminder",
    data: {
      identifier: formData.identifier,
      base_url: window.location.origin
    },
    params: {},
    headers: {}
  });
}
