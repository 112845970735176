import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import InventoryListService from "../../services/inventory/InventoryListService";
import InventorytransferListService from "../../services/inventorytransfer/InventorytransferListService";
import moment from "moment";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import InventoryDownloadService from "../../services/inventory/InventoryDownloadService";
import download from "js-file-download";
import InventorytransferDownloadXlsService from "../../services/inventorytransfer/InventorytransferDownloadXlsService";
import InventorytransferDownloadPdfService from "../../services/inventorytransfer/InventorytransferDownloadPdfService";

const InventorytransferListComponent = (props) => {

  const [inventory, setInventory] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const dt = useRef(null);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProductList: false})
      InventorytransferListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setInventory(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteInventory = (rowData) => {
    // InventoryDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button type="button" label={"XLS"} tooltip="XLS Letöltés" icon={"pi pi-download"}
                onClick={() => {
                  InventorytransferDownloadXlsService(rowData.id)
                    .then(response => {
                      window.App.toastShow('success', 'Sikeres letöltés!', '')
                      download(response.data, localStorage.getItem('networkName')
                        + '_'
                        +
                        'arumozgas'
                        + '_'
                        +
                        rowData.id
                        + '_'
                        + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                        + '.xlsx')
                      setLoading(false)
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!');
                      setLoading(false)
                    })
                }}
                className={"p-button p-text-light p-mr-2"}
        />
        <Button type="button" label={"PDF"} tooltip="PDF Letöltés" icon={"pi pi-download"}
                onClick={() => {
                  InventorytransferDownloadPdfService(rowData.id)
                    .then(response => {
                      window.App.toastShow('success', 'Sikeres letöltés!', '')
                      download(response.data, localStorage.getItem('networkName')
                        + '_'
                        +
                        'arumozgas'
                        + '_'
                        +
                        rowData.id
                        + '_'
                        + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                        + '.pdf')
                      setLoading(false)
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!');
                      setLoading(false)
                    })
                }}
                className={"p-button-secondary p-text-light"}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
    WarehouseListService()
      .then(response => {
        setWarehouses(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [lazyParams, window.App.state.rerenderInventorytransferList === true])

  return (
    <DataTable
      emptyMessage="Nincs találat."
      value={inventory} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
      ref={dt}
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="action" header="Típus" sortable body={(rowData) => {
       if(rowData.action === 'waste') {
         return (
           <>Selejt</>
         )
       }
       else if(rowData.action === 'move') {
         return (
           <>Árumozgatás</>
         )
       }
       else if(rowData.action === 'control') {
         return (
           <>Leltár</>
         )
       }
      }}></Column>
      <Column field="warehouseFromName" header="Indulási raktár" sortable filter
              filterElement={() => {
                return(
                  <>
                    {lazyParams && lazyParams.filters && lazyParams.filters.warehouseFrom ?(
                      <Dropdown options={warehouses}
                                id={"warehouseFrom"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs raktár..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'warehouseFrom', '');
                                }}
                                value={lazyParams.filters.warehouseFrom.value}
                      />
                    ):(
                      <Dropdown options={warehouses}
                                id={"warehouseFrom"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs raktár..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'warehouseFrom', '');
                                }}
                      />
                    )}
                  </>
                )
              }}
      ></Column>
      <Column field="warehouseToName" header="Érkezési raktár" sortable filter
              filterElement={() => {
                return(
                  <>
                    {lazyParams && lazyParams.filters && lazyParams.filters.warehouseTo ?(
                      <Dropdown options={warehouses}
                                id={"warehouseFrom"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs raktár..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'warehouseTo', '');
                                }}
                                value={lazyParams.filters.warehouseTo.value}
                      />
                    ):(
                      <Dropdown options={warehouses}
                                id={"warehouseFrom"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs raktár..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'warehouseTo', '');
                                }}
                      />
                    )}
                  </>
                )
              }}
      ></Column>
      <Column field="createdByName" header="Kérte" sortable filter filterPlaceholder="Kérte"></Column>
      <Column field="createdAt" header="Időpont" sortable className={"p-text-nowrap"} body={(rowData) => {
        return (
          <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm')}</>
        )
      }}></Column>
      {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
      {/*<Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>*/}
      {/*<Column field="warehouseName" header="Raktár" sortable filter filterPlaceholder="Raktár"></Column>*/}
      {/*<Column field="sku" header="Termék cikkszám (SKU)" sortable filter filterPlaceholder="Termék cikkszám (SKU)"></Column>*/}
      {/*<Column field="upc" header="Gyártó cikkszám (EAN/UPC)" sortable filter filterPlaceholder="Gyártó cikkszám (EAN/UPC)"></Column>*/}
      {/*<Column field="stockFrom" header="Mennyiségtől" sortable filter filterPlaceholder="Mennyiségtől"></Column>*/}
      {/*<Column field="stockUntil" header="Mennyiségig" sortable filter filterPlaceholder="Mennyiségig"></Column>*/}
    </DataTable>

  )
}
export default withTranslation('common')(InventorytransferListComponent);
