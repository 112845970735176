import axios from "axios";

export default function ManufacturingSummaryCapacityForecastService() {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/manufacturing/summary/capacityForecast",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
