import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch";
import {Panel} from "primereact/panel";

import EntryterminalFormService from '../../services/entryterminal/EntryterminalFormService'
import EntryzoneListService from "../../services/entryzone/EntryzoneListService";
import {Dropdown} from "primereact/dropdown";
import {FileUpload} from "primereact/fileupload";
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";

const EntryterminalFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.identifier) {
    //   errors.identifier = 'Az azonosító nem lehet üres';
    // }
    // if (!data.entryZone) {
    //   errors.entryZone = 'A zóna nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      EntryterminalFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderEntryterminalList: true,
            sidebarEntryterminalUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      EntryterminalFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderEntryterminalList: true,
            sidebarEntryterminalCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // image
  const imageUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      var base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data
      })
    }
    let file = reader.readAsDataURL(files.files[0]);
  }

  // entryZone autocomplete
  const [entryZones, setentryZones] = useState([]);
  const [selectedEntryZone, setSelectedEntryZone] = useState('');

  const searchEntryZone = (event) => {
    EntryzoneListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let _zones = []
          Object.entries(response.data.items).map(function (zone, index) {
            _zones.push({
              name: zone[1].name + " - " + zone[1].entryLocationName,
              id: zone[1].id
            })
          })
          setentryZones(_zones)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(props.initialValues)
      setSelectedEntryZone(props.initialValues.entryZoneName)

    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-8 p-xl-8"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                      <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="identifier" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="identifier"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Azonosító</label>
                      <span className="p-input-icon-right">
                      <InputText id="identifier" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     identifier: e.target.value
                                   })
                                 }}
                                 value={formData.identifier}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="entryZone" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="entryZone"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Zóna</label>
                      <span className="p-input-icon-right">
                    <AutoComplete value={selectedEntryZone}
                                  id={"entryLocation"}
                                  suggestions={entryZones}
                                  forceSelection
                                  completeMethod={searchEntryZone}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      entryZone: {id: e.value.id}
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      entryZone: {id: null}
                                    })
                                    setSelectedEntryZone(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedEntryZone('')
                                  }}
                    />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="localUnitIP" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="localUnitIP"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Központi vezérlő IP címe</label>
                      <span className="p-input-icon-right">
                      <InputText id="localUnitIP" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     localUnitIP: e.target.value
                                   })
                                 }}
                                 value={formData.localUnitIP}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              {/*<div className={"p-grid"}>*/}
              {/*  {formData.id &&*/}
              {/*  <>*/}
              {/*    <div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
              {/*      <label>Hiba státusz</label>*/}
              {/*      <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
              {/*        {formData.status}*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
              {/*      <label>Üzemmód</label>*/}
              {/*      <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
              {/*        {formData.mode}*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
              {/*      <label>Utolsó módosítás</label>*/}
              {/*      <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
              {/*        {formData.updatedAt !== null ? (*/}
              {/*          <>{moment.utc(formData.updatedAt).local().format('YYYY-MM-DD')}</>*/}
              {/*        ) : (*/}
              {/*          <>Nincs adat</>*/}
              {/*        )}*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 panel-highlight"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-image"}></i> Fotó
                  </h3>
                </div>
              </div>
              <div className="p-field p-fluid">
                {formData.file &&
                <>
                  <label htmlFor="file">Feltöltött kép</label>
                  <img src={formData.file} className={"w-100 p-d-block"}/>
                </>
                }
                {formData.id && !formData.file &&
                <>
                  <label htmlFor="file">Feltöltött kép</label>
                  <img
                    src={process.env.REACT_APP_API_HOST + "/entry_terminal/" + formData.id + "/download?token="
                    + localStorage.getItem("userToken") + "?date=" + new (Date)}
                    className={"w-100 p-d-block p-mb-2"}/>
                </>
                }
              </div>
              <div className="p-field p-fluid">
                <label htmlFor="file">Kép feltöltése</label>
                <span className="p-d-block">
                          <FileUpload mode="basic"
                                      name="file"
                                      id={"file"}
                                      auto="true"
                                      maxFileSize={1000000}
                                      customUpload
                                      uploadHandler={imageUpload}
                                      chooseLabel={"Fájl kiválasztása"}
                                      uploadLabel={"Feltöltés"}
                                      className={""}
                          />
                        </span>
              </div>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EntryterminalFormComponent);
