import axios from "axios";

export default function ReceiptFileDeleteService(receiptId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/deleteExternalFile",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
