import axios from "axios";

export default function EmployeeExportService(format, ids) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/employee/export",
    responseType: 'blob',
    data: {
      format: format,
      ids: ids,
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  })
};
