import axios from "axios";

export default function OfferitemPricelistitemFormService(offerId, itemId, formData, method) {
  let productId
  let url
  if(formData && formData.id) {
    productId = formData.id
    url = process.env.REACT_APP_API_HOST + "/offer/" + offerId + "/item/" + itemId + "/priceListItem/" + productId
  }
  else {
    url = process.env.REACT_APP_API_HOST + "/offer/" + offerId + "/item/" + itemId + "/priceListItems"
  }
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
