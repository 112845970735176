import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import EntrylocationInviteOwnerService from "../../services/entrylocation/EntrylocationInviteOwnerService";

const EntrylocationFormComponent = (props) => {

  const [formData, setFormData] = useState({
    entryLocation: {'id': Number(localStorage.getItem('networkId'))}
  });
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.lastName) {
    //   errors.lastName = 'Kötelező mező';
    // }
    // if (!data.firstName) {
    //   errors.firstName = 'Kötelező mező';
    // }
    // if (!data.email) {
    //   errors.email = 'Az email mező nem lehet üres.';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
    //   errors.email = 'Mindenképpen email formátumot adj meg! Pl. example@email.com';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    EntrylocationInviteOwnerService(data, window.App.state.props)
      .then(response => {
        window.App.toastShow('success', 'Sikeres meghívó küldés!');
        window.App.setState({dialogEntrylocationOwner: false})
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit}
          initialValues={formData}
          validate={validate}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="">
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="lastName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="lastName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Vezetéknév</label>
                      <span className="p-input-icon-right">
                              <InputText id="lastName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             lastName: e.target.value
                                           })
                                         }}
                                         value={formData.lastName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="firstName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="firstName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Keresztnév</label>
                      <span className="p-input-icon-right">
                              <InputText id="firstName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             firstName: e.target.value
                                           })
                                         }}
                                         value={formData.firstName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                      <span className="p-input-icon-right">
                              <InputText id="email" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             email: e.target.value
                                           })
                                         }}
                                         value={formData.email}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="phone" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="phone"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Telefon</label>
                      <span className="p-input-icon-right">
                              <InputText id="phone" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             phone: e.target.value
                                           })
                                         }}
                                         value={formData.phone}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit" label="Meghívó küldése" icon="pi pi-check" loading={loading}/>
                </div>
              </div>
            </form>
          )}/>
  )
}
export default withTranslation('common')(EntrylocationFormComponent);
