import axios from "axios";

export default function UnasFormService(formData, method) {
  let id
  let url
  if(formData && formData.id) {
    id = formData.id
    url = process.env.REACT_APP_API_HOST + "/unas/" + id
  }
  else {
    url = process.env.REACT_APP_API_HOST + "/unas"
  }
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
