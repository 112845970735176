import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {FileUpload} from "primereact/fileupload";
import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import download from "js-file-download";

import EmployeeImportService from "../../services/employee/EmployeeImportService";
import CompanyListService from "../../services/company/CompanyListService";
import EmployeeExportService from "../../services/employee/EmployeeExportService";
import ImportqueueListComponent from "../importqueue/ImportqueueListComponent";

const EmployeeImportComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    EmployeeImportService(formData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setFormData({...formData, xls: null})
        window.App.setState({
          rerenderEmployeeList: true,
          rerenderImportqueueList: true,
          sidebarReceiptCostImport: false
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    let name = files.files[0].name
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, ''),
        originalFileName: name
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">

        <Panel className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
              <h3>
                <i className={"pi pi-download"}></i> Fájl letöltése
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              Új munkavállalók hozzáadásához kérjük töltse ki a mellékelt minta XLS fájlt.
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6 p-text-right p-col-align-center"}>
              <a href="/download/munkavallalok_import_minta_v2.xlsx"
                 download
                 target="_blank"
                 className={"p-button p-d-block p-text-light"}>
                <i className={"pi pi-download"}></i>&nbsp;Minta XLS letöltése (v2.0)
              </a>
            </div>
          </div>
          {localStorage.getItem('roles').toString().includes('role_employee_export') &&
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              Meglévő munkavállalók adatainak utólagos pótlásához kérjük töltse le és egészítse ki a mellékelt XLS
              fájlt.
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6 p-text-right p-col-align-center"}>
              <a onClick={() => {
                EmployeeExportService()
                  .then(response => {
                    download(response.data, localStorage.getItem('networkName')
                      + '_'
                      + 'munkavallalo-export'
                      + '_'
                      + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                      + '.xlsx')
                    setLoading(false)
                  })
                  .catch(error => {
                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    setLoading(false)
                  })
              }}
                 download
                 target="_blank"
                 className={"p-button p-d-block p-text-light"}>
                <i className={"pi pi-download"}></i>&nbsp;Munkavállalók exportálása
              </a>
            </div>
          </div>
          }
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Cég kiválasztása, fájl feltöltése
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                  <span className="p-input-icon-right">
                                        {localStorage.getItem('roles').toString().includes('role_company_browse')
                                        || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                                          <AutoComplete value={selectedCompany}
                                                        id={"company"}
                                                        suggestions={companies}
                                                        forceSelection
                                                        completeMethod={searchCompany}
                                                        field={"name"}
                                                        delay="1000"
                                                        placeholder={"Keresés gépeléssel..."}
                                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                        onSelect={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: e.value.id}
                                                          })
                                                        }}
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            company: {id: null}
                                                          })
                                                          setSelectedCompany(e.value)
                                                        }}
                                                        dropdown
                                                        onClear={(e) => {
                                                          setSelectedCompany('')
                                                        }}
                                          />
                                        ) : (
                                          <Dropdown disabled className={"disabled"} id={"company"}/>
                                        )}
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
              {/*<Field name="company" render={({input, meta}) => (*/}
              {/*  <div className="p-field p-fluid">*/}
              {/*    <label htmlFor="company"*/}
              {/*           className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>*/}
              {/*    <span className="p-input-icon-right">*/}
              {/*            {formData && formData.company && formData.company.id ? (*/}
              {/*              <Dropdown options={companies}*/}
              {/*                        optionLabel="name"*/}
              {/*                        optionValue="id"*/}
              {/*                        id={"company"}*/}
              {/*                        emptyMessage={"Még nincs cég..."}*/}
              {/*                        onChange={(e) => {*/}
              {/*                          setFormData({*/}
              {/*                            ...formData,*/}
              {/*                            company: {id: e.value}*/}
              {/*                          })*/}
              {/*                        }}*/}
              {/*                        value={Number(formData.company.id)}*/}
              {/*              />*/}
              {/*            ) : (*/}
              {/*              <Dropdown options={companies}*/}
              {/*                        optionLabel="name"*/}
              {/*                        optionValue="id"*/}
              {/*                        id={"company"}*/}
              {/*                        emptyMessage={"Még nincs cég..."}*/}
              {/*                        onChange={(e) => {*/}
              {/*                          setFormData({*/}
              {/*                            ...formData,*/}
              {/*                            company: {id: e.value}*/}
              {/*                          })*/}

              {/*                        }}*/}
              {/*              />*/}
              {/*            )}*/}
              {/*    </span>*/}
              {/*    {getFormErrorMessage(meta)}*/}
              {/*  </div>*/}
              {/*)}/>*/}
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="file" render={({input, meta}) => (
                <div className="p-field">
                  {!formData.file ? (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                      <span className="">
                                                         <FileUpload mode="basic"
                                                                     name="file"
                                                                     id={"file"}
                                                                     accept=""
                                                                     auto="true"
                                                                     maxFileSize={1000000}
                                                                     customUpload
                                                                     className={"p-d-block"}
                                                                     uploadHandler={fileUpload}
                                                                     chooseLabel={"Fájl kiválasztása"}
                                                                     uploadLabel={"Feltöltés"}
                                                         />
                                                      </span>
                    </>
                  ) : (
                    <>
                      <label htmlFor="image"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                        törlése</label>
                      <span className="p-d-block">
                        <div className={"p-grid"}>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <p className={"p-m-0 p-pt-2"}>
                              <i className={"pi pi-file"}></i> {formData.originalFileName}
                            </p>
                          </div>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <Button type="button"
                                    className="p-button p-button-danger"
                                    onClick={() => setFormData({...formData, file: null})}
                            >Törlés</Button>
                          </div>
                        </div>
                      </span>
                    </>
                  )}
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {formData.file ? (
                <Button type="submit" label="Import indítása" icon={"pi pi-check"} loading={loading}
                        className="p-button-success"/>
              ) : (
                <Button type="button" label="Import indítása" icon={"pi pi-check"}
                        className="p-button-success"
                        onClick={() => {
                          alert('A feltöltendő fájl XML formátum kell legyen!')
                        }}
                />
              )}
            </div>
          </div>
        </Panel>
        <Panel className={"p-mt-3"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
              <h3>
                <i className={"pi pi-upload"}></i> Import folyamatok
              </h3>
            </div>
          </div>
          <ImportqueueListComponent />
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EmployeeImportComponent);
